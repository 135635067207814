import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Divider, Paper, Link, Stack, Typography } from '@mui/material'

export default function GeneratorLink(props) {
  const navigate = useNavigate()

  return (
    <Paper elevation={3} onClick={(e) => navigate(props.to)} style={{ cursor: 'pointer' }}>
      <Stack
        direction="row"
        padding={3}
        spacing={3}
        alignItems="center"
        justifyContent="flex-start"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Link to={props.to} component={RouterLink}>
          {props.children}
        </Link>
        <Stack direction="column" spacing={1}>
          <Link to={props.to} component={RouterLink}>
            <Typography variant="primary">{props.title}</Typography>
          </Link>
          {props.description && <Typography variant="secondary">{props.description}</Typography>}
        </Stack>
      </Stack>
    </Paper>
  )
}
import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import Cropper from "react-cropper"

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import SaveIcon from '@mui/icons-material/Save'
import TransformIcon from '@mui/icons-material/Transform'
import UndoIcon from '@mui/icons-material/Undo'

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

export default function WordCreateSinglePage() {
  const theme = useContext(ThemeContext)
  const navigate = useNavigate()

  const [word, setWord] = useState("")
  const [translation, setTranslation] = useState("")
  const [imageUrl, setImageUrl] = useState(null)
  const [croppedImageBase64, setCroppedImageBase64] = useState(null)
  const [imageBase64, setImageBase64] = useState(null)
  const [filename, setFilename] = useState(null)
  const [cropper, setCropper] = useState(null)


  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getImageUrl = (e) => {
    if (e.target.files) {
      // Encode the file using the FileReader API
      const reader = new FileReader()
      reader.onloadend = () => {
        // Logs wL2dvYWwgbW9yZ...
        setImageBase64(reader.result)
      }
      reader.readAsDataURL(e.target.files[0])
      setFilename(`${uuid()}.${e.target.files[0].name.split('.').pop()}`)
      setImageUrl(URL.createObjectURL(e.target.files[0]))
    }
  }

  const getCropData = async () => {
    if (cropper) {
      setCroppedImageBase64(cropper.getCroppedCanvas().toDataURL())
    }
  }

  const submitForm = (e) => {
    e.preventDefault()

    if ([
      word.length > 0,
      translation.length > 0,
    ].some(x => x === false)) {
      return false
    }

    let image = null

    if (croppedImageBase64) {
      image = {
        base64: croppedImageBase64,
        name: filename,
      }
    } else if (imageUrl) {
      image = {
        base64: imageBase64,
        name: filename,
      }
    }

    rpc.post(
      "",
      buildRequest(
        "admin.vocabulary.words.create_single",
        {
          word,
          translation,
          image,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        navigate(`/words/details/${response.data.result.id}`)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        setErrorMessage(response.data.result.message)
      }
    })
    return false
  }

  return (
    <>
      <Helmet>
        <title>Create Word</title>
      </Helmet>
      <Fab
        color="primary"
        aria-label="save"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        disabled={[
          word.length > 0,
          translation.length > 0,
        ].some(x => x === false)}
        onClick={submitForm}
      >
        <SaveIcon />
      </Fab>

      <Container fixed>
        <form onSubmit={submitForm} method="post">
          <Box sx={{ flexGrow: 1, marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant="h4" component="h1" gutterBottom>
                    New Word
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Paper>
                      <Stack padding={1} spacing={1}>
                        <TextField
                          label="Word"
                          error={word.length < 1}
                          helperText={word.length > 0 ? "" : "Word is required"}
                          value={word}
                          onChange={(e) => setWord(e.target.value)}
                        />

                        <TextField
                          label="Translation"
                          error={translation.length < 1}
                          helperText={translation.length > 0 ? "" : "Translation is required"}
                          value={translation}
                          onChange={(e) => setTranslation(e.target.value)}
                        />
                      </Stack>
                    </Paper>
                  </Grid>
                  {errorMessage &&
                    <Grid item xs={12}>
                      <Paper>
                        <Alert severity="error">{errorMessage}</Alert>
                      </Paper>
                    </Grid>
                  }
                  {(imageUrl && !croppedImageBase64) && (
                    <Grid item xs={12}>
                      <Paper>
                        <Stack padding={1} spacing={1}>
                          <Cropper
                            src={imageUrl}
                            style={{ height: 400, width: 400 }}
                            aspectRatio={168 / 142}
                            minCropBoxWidth={162}
                            minCropBoxHeight={148}
                            guides={false}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                              setCropper(instance);
                            }}
                          />
                          <Button variant="contained" onClick={getCropData}>
                            <TransformIcon /> Crop Image
                          </Button>
                        </Stack>
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <Stack padding={1} spacing={1}>
                    <Button
                      variant="contained"
                      component="label"
                    >
                      Upload image
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
                        hidden
                        onChange={getImageUrl}
                      />
                    </Button>

                    {imageUrl && (
                      <>
                        <img src={croppedImageBase64 || imageUrl} alt={word} />
                        <Button
                          variant="contained"
                          disabled={!croppedImageBase64}
                          onClick={() => setCroppedImageBase64(null)}
                        >
                          <UndoIcon /> Recrop Image
                        </Button>
                      </>
                    )}

                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </>
  )
}

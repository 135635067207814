import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDropzone } from 'react-dropzone'
import { v4 as uuid } from 'uuid'
import { Link as RouterLink, useParams } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress'

import Autocomplete from '@mui/material/Autocomplete';
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import ImageList from '@mui/material/ImageList'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import MuiAlert from '@mui/material/Alert'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Link from '@mui/material/Link'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import SaveIcon from '@mui/icons-material/Save'
import DeleteIcon from '@mui/icons-material/Delete'

import AssignmentLink from 'components/elements/AssignmentLink'
import AssignmentStatus from 'components/elements/AssignmentStatus'
import Editor from 'components/elements/Editor'
import AssignmentImage from 'components/elements/AssignmentImage'
import AssignmentAudio from 'components/elements/AssignmentAudio'
import TabPanel, { a11yProps } from 'components/common/TabPanel'
import HashtagField from 'components/forms/HashtagField'

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'
import { CDN_URL } from 'config'
import { buildAssignmentSubmitRequest } from 'lib/assignment-utils'

const PageAlert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function AssignmentDetailsPage() {
  const theme = useContext(ThemeContext)

  let { assignmentId } = useParams()

  const [assignment, setAssignment] = useState(null)
  const [files, setFiles] = useState([])
  const [audio, setAudio] = useState([])

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")

  const [errorMessage, setErrorMessage] = useState(null)
  const [tabValue, setTabValue] = useState(0)

  const [showAll, setShowAll] = useState(
    localStorage.getItem('assign_showAll') === 'true' ? true : false
  )
  const [lessonsAssignedTo, setLessonsAssignedTo] = useState(null)
  const [options, setOptions] = useState([])
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedLesson, setSelectedLesson] = useState(null)
  const loading = searchOpen && options.length === 0

  // Assignment checker
  const [assignmentCheckHasErrors, setAssignmentCheckHasErrors] = useState(false)
  const [assignmentCheckMessage, setAssignmentCheckMessage] = useState(null)
  const [assignmentCheckTracing, setAssignmentCheckTracing] = useState(null)
  const [assignmentCheckerLoading, setAssignmentCheckerLoading] = useState(false)
  const [assignmentCheckEmoji, setAssignmentCheckEmoji] = useState(null)
  const [assignmentCheckScore, setAssignmentCheckScore] = useState(null)
  const [assignmentCheckAnswersData, setAssignmentCheckAnswersData] = useState(null)

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'audio/*': ['.mp3'],
    },
    onDrop: acceptedFiles => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          setFiles(
            [...files,
            ...acceptedFiles.filter(file => file.type.includes('image')).map(file => {
              const filename = `${uuid()}.${file.name.split('.').pop()}`
              return Object.assign(file, {
                filename: filename,
                preview: URL.createObjectURL(file),
                base64Image: e.target.result,
                link: `${CDN_URL}/media/assignments/${filename}`,
              })
            })
            ]
          )

          setAudio(
            [...audio,
            ...acceptedFiles.filter(file => file.type.includes('audio')).map(file => {
              const filename = `${uuid()}.${file.name.split('.').pop()}`
              return Object.assign(file, {
                filename: filename,
                link: `https://cdn.icepig.online/media/audio/${filename}`,
                base64audio: e.target.result,
              })
            })
            ]
          )
        }
        reader.readAsDataURL(file)
      })
    }
  })

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  }

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const setHashtags = (e, newValue) => {
    const newHashtags = newValue.map(hashtag => {
      if (!hashtag.startsWith('#')) {
        return `#${hashtag}`
      } else {
        return hashtag
      }
    })
    setAssignment({ ...assignment, hashtags: newHashtags.join(' ') })
  }

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page

    rpc.post(
      "",
      buildRequest(
        "admin.assignments.details",
        { assignment_id: assignmentId },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setAssignment(response.data.result)
        setErrorMessage(null)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (tabValue === 1 && !lessonsAssignedTo) {
      rpc.post(
        "",
        buildRequest(
          "admin.assignments.lessons",
          {
            assignment_id: assignmentId,
          }
        )
      ).then(response => {
        if (!response.data.result.message) {
          setLessonsAssignedTo(response.data.result)
        } else {
          if (response.data.result.code === 5) {
            signoutAndRedirectToIndex()
          }
          console.error(response.data.result)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue])

  useEffect(() => {
    let active = true

    if (searchTerm.length < 1 || isNaN(searchTerm)) {
      if (active) {
        setOptions([])
      }
    } else {
      rpc.post(
        "",
        buildRequest(
          "admin.lessons.search",
          {
            number: searchTerm,
            show_all: !showAll,
          }
        )
      )
        .then(response => {
          if (!response.data.result.message) {
            if (active) {
              setOptions([...response.data.result])
            }
          } else {
            if (response.data.result.code === 5) {
              signoutAndRedirectToIndex()
            }
            console.error(response.data.result)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    return () => {
      active = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, searchTerm])

  useEffect(() => {
    if (!setSearchOpen) {
      setOptions([])
    }
  }, [setSearchOpen])

  useEffect(() => {
    localStorage.setItem('assign_showAll', showAll)
  }, [showAll])

  const removeFile = (index) => {
    URL.revokeObjectURL(files[index].preview)
    setFiles(files.filter((_file, i) => i !== index))
  }

  const removeAudio = (index) => {
    setAudio(audio.filter((_file, i) => i !== index))
  }

  const thumbs = files.map((file, index) => (
    <AssignmentImage
      key={`assignmentImageFile_${index}`}
      preview={file.preview}
      link={file.link}
      alt={file.name}
      index={index}
      removeFile={removeFile}
    />
  ));

  const audios = audio.map((file, index) => (
    <AssignmentAudio
      key={`assignmentAudioFile_${index}`}
      link={file.link}
      alt={file.name}
      index={index}
      removeFile={removeAudio}
    />
  ))

  const submitForm = (e) => {
    e.preventDefault()

    rpc.post(
      "",
      buildRequest(
        "admin.assignments.update",
        {
          assignment_id: assignment.id,
          name: assignment.name,
          kind: assignment.kind,
          hashtags: assignment.hashtags,
          assignment_level: assignment.assignment_level,
          source_yaml: assignment.source_yaml,
          images: files.map(file => ({
            name: file.filename,
            base64: file.base64Image
          })),
          audio: audio.map(file => ({
            name: file.filename,
            base64: file.base64audio,
          }))
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setErrorMessage(null)
        setMessage(`Assignment [${assignment.id}] has been updated`)
        files.forEach((file, i) => removeFile(i))
        audio.forEach((file, i) => removeAudio(i))
        setAssignment(response.data.result)
        performCheck(response.data.result)
        setOpen(true)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        setErrorMessage(response.data.result.message)
        console.error(response.data.result)
      }
    })
    return false
  }

  const handleAssignToLesson = () => {
    rpc.post(
      "",
      buildRequest(
        "admin.assignment.attach",
        {
          assignment_id: assignment.id,
          lesson_id: selectedLesson.id,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setErrorMessage(null)
        setMessage(`Assignment [${assignment.id}] has been assigned to lesson [${selectedLesson.id}]`)
        setOpen(true)
        setLessonsAssignedTo(
          [
            response.data.result,
            ...lessonsAssignedTo.filter(lesson => lesson.id !== response.data.result.id)
          ]
        )
        setSelectedLesson(null)
        setSearchTerm("")
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        setErrorMessage(response.data.result.message)
        console.error(response.data.result)
      }
    })
  }

  const handleDetach = (lessonId) => {
    rpc.post(
      "",
      buildRequest(
        "admin.assignment.detach",
        {
          assignment_id: assignmentId,
          lesson_id: lessonId,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setErrorMessage(null)
        setMessage(`Assignment [${assignmentId}] has been detached from lesson [${lessonId}]`)
        setOpen(true)
        setLessonsAssignedTo([...lessonsAssignedTo.filter(lesson => lesson.lesson.id !== lessonId)])
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        setErrorMessage(response.data.result.message)
        console.error(response.data.result)
      }
    })
  }

  const performCheck = (assignment) => {
    if (assignment.full_type === "v2_theory") {
      setAssignmentCheckEmoji("🤖")
      setAssignmentCheckScore(100)
      setAssignmentCheckHasErrors(false)
      setAssignmentCheckMessage("Theory assignments are always correct")
      setAssignmentCheckTracing(null)
      return
    }
    setAssignmentCheckerLoading(true)
    // construct the request data to submit the assignment
    const assignmentSubmitRequestData = buildAssignmentSubmitRequest(assignment.full_type, assignment.data)
    setAssignmentCheckAnswersData(assignmentSubmitRequestData)
    // now we need to perform an rpc call to submit the assignment to the RPC method courses.assignment.submit_dry_run
    rpc.post(
      "",
      buildRequest(
        "courses.assignment.submit_dry_run",
        {
          assignment_id: assignment.id,
          answers: assignmentSubmitRequestData,
        },
      )
    ).then(response => {
      setAssignmentCheckerLoading(false)
      if (response.data.result.code || response.data.result.error) {
        setAssignmentCheckEmoji(null)
        setAssignmentCheckScore(null)
        setAssignmentCheckHasErrors(true)
        setAssignmentCheckMessage(response.data.result.error ? response.data.result.error.message : response.data.result.message)
        setAssignmentCheckTracing(response.data.result.error ? response.data.result.error.traceback : "Traceback is unavailable")
      } else {
        setAssignmentCheckHasErrors(false)
        setAssignmentCheckMessage(null)
        setAssignmentCheckTracing(null)
        setAssignmentCheckEmoji(response.data.result.emoji)
        setAssignmentCheckScore(response.data.result.score)
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Edit Assignment</title>
      </Helmet>

      {assignment && (
        <Fab
          color="primary"
          aria-label="save"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          disabled={[
            assignment.name.length > 0,
            assignment.source_yaml.length > 0,
          ].some(x => !x)}
          onClick={submitForm}
        >
          <SaveIcon />
        </Fab>
      )}

      <Container fixed>
        <form onSubmit={submitForm} method="post">
          <Box sx={{ flexGrow: 1, marginTop: 2 }}>
            <Grid container>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} justifyContent={'space-between'}>
                  <Typography variant="h4" component="h1" gutterBottom>
                    Edit Assignment
                  </Typography>

                  <Tabs value={tabValue} onChange={handleTabValueChange} aria-label="basic tabs example">
                    <Tab label="Edit" {...a11yProps(0)} />
                    <Tab label="Attach" {...a11yProps(1)} />
                  </Tabs>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <TabPanel value={tabValue} index={0}>
                  <Grid container>
                    <Grid item xs={12}>
                      <AssignmentLink {...assignment} />

                      <Paper>
                        {assignment ?
                          <Stack padding={1} spacing={1}>
                            <TextField
                              label="Name"
                              value={assignment.name}
                              onChange={(e) => setAssignment({ ...assignment, name: e.target.value })}
                            />
                            <Select
                              labelId="level-select-label"
                              id="level-select"
                              value={assignment.assignment_level}
                              label="Level"
                              onChange={(e) => setAssignment({ ...assignment, assignment_level: e.target.value })}
                            >
                              <MenuItem value={"a0"}>A0</MenuItem>
                              <MenuItem value={"a1"}>A1</MenuItem>
                              <MenuItem value={"a1plus"}>A1+</MenuItem>
                              <MenuItem value={"a2"}>A2</MenuItem>
                              <MenuItem value={"b1"}>B1</MenuItem>
                              <MenuItem value={"b2"}>B2</MenuItem>
                              <MenuItem value={"c1"}>C1</MenuItem>
                            </Select>
                            <TextField
                              label="Kind"
                              value={assignment.kind}
                              onChange={(e) => setAssignment({ ...assignment, kind: e.target.value })}
                              helperText="Prepositions, collocations, etc."
                            />
                            <HashtagField
                              defaultValue={assignment.hashtags ? assignment.hashtags.split(' ') : []}
                              setHashtags={setHashtags}
                            />
                          </Stack>
                          : <CircularProgress />
                        }
                      </Paper>
                    </Grid>
                    {errorMessage &&
                      <Grid item xs={12} sx={{ marginTop: 2 }}>
                        <Paper>
                          <Alert severity="error">{errorMessage}</Alert>
                        </Paper>
                      </Grid>
                    }
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <Paper>
                        {assignment ?
                          <Editor
                            defaultValue={assignment.source_yaml}
                            setValue={(newValue) => setAssignment({ ...assignment, source_yaml: newValue })}
                          />
                          :
                          <CircularProgress />
                        }
                        <AssignmentStatus
                          loading={assignmentCheckerLoading}
                          hasErrors={assignmentCheckHasErrors}
                          message={assignmentCheckMessage}
                          tracing={assignmentCheckTracing}
                          emoji={assignmentCheckEmoji}
                          score={assignmentCheckScore}
                          answersData={assignmentCheckAnswersData}
                        />
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <Paper>
                        <Typography
                          variant="overline"
                          padding={1}
                          gutterBottom
                        >
                          Assignment Images & Audio
                        </Typography>
                        <div>
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Drag 'n' drop some files here, or click to select files</p>
                          </div>
                          <ImageList>
                            {thumbs}
                            {assignment && assignment.images.map(image =>
                              <AssignmentImage
                                key={`assignmentImage_${image.id}`}
                                preview={image.image}
                                link={image.image}
                                alt={image.id}
                              />
                            )}
                            {audios}
                            {assignment && assignment.audio.map(audio =>
                              <AssignmentAudio
                                key={`assignmentAudio_${audio.id}`}
                                link={audio.audio}
                                alt={audio.id}
                              />
                            )}
                          </ImageList>
                        </div>
                      </Paper>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Paper>
                        <Stack spacing={1} padding={1}>
                          <Typography variant="overline" padding={1} gutterBottom>
                            Assign to Lesson
                          </Typography>
                          <FormControlLabel control={
                            <Switch
                              checked={showAll}
                              onChange={(e) => setShowAll(e.target.checked)}
                            />
                          } label="Only my lessons" />
                          <Autocomplete
                            id="asynchronous-demo"

                            open={searchOpen}
                            onOpen={() => {
                              setSearchOpen(true);
                            }}
                            onClose={() => {
                              setSearchOpen(false);
                            }}
                            onChange={(e, newValue) => setSelectedLesson(newValue)}
                            filterOptions={(x) => x}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => `${option.number} ${option.group.name}`}
                            options={options}
                            loading={loading}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                label="Search for a lesson by its NUMBER"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  ),
                                }}
                              />
                            )}
                          />

                          <Button
                            disabled={!selectedLesson}
                            variant="contained"
                            onClick={handleAssignToLesson}
                          >
                            {selectedLesson ? (
                              <>Assign[{assignmentId}] to {selectedLesson.number}.{selectedLesson.group.name}</>
                            ) : (
                              <>Find a lesson</>
                            )}
                          </Button>
                        </Stack>
                      </Paper>
                    </Grid>

                    <Grid item xs={12}>
                      <Paper>
                        {lessonsAssignedTo ? (
                          <Stack padding={1} spacing={1}>
                            {lessonsAssignedTo.map(lesson =>
                              <Stack
                                key={`lesson_assignment_pk_${lesson.id}`}
                                direction="row"
                                spacing={1}
                                justifyContent={'space-between'}
                                alignItems={'center'}>
                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                  <Typography variant="overline">
                                    {lesson.lesson.number}
                                  </Typography>
                                  <Typography variant="primary">
                                    {lesson.lesson.group.name}
                                  </Typography>
                                  <Typography variant="primary">
                                    <Link to={`/groups/details/${lesson.lesson.group.id}/lesson/${lesson.lesson.id}`} component={RouterLink}>lesson {lesson.lesson.number}</Link>
                                  </Typography>
                                </Stack>
                                <IconButton size="small" onClick={(e) => handleDetach(lesson.lesson.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Stack>
                            )}
                          </Stack>
                        ) : (<CircularProgress />)}
                      </Paper>
                    </Grid>
                  </Grid>
                </TabPanel>
              </Grid>
            </Grid>
          </Box>
        </form >

        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          autoHideDuration={2000}
          onClose={handleClose}
          action={action}
        >
          <PageAlert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {message}
          </PageAlert>
        </Snackbar>
      </Container >
    </>
  )
}

import React, { useState } from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import MuiAlert from '@mui/material/Alert';

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AttachAssignmentForm(props) {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [assignmentId, setAssignmentId] = useState(null)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const submitForm = (e) => {
    e.preventDefault()

    rpc.post(
      "",
      buildRequest(
        "admin.assignment.attach",
        {
          lesson_id: props.lessonId,
          assignment_id: assignmentId,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        if (response.data.result.id) {
          setMessage(`Assignment [${assignmentId}] attached to the Lesson [${props.lessonId}]`)
          setAssignmentId('')
          props.refreshLesson(props.lessonId)
          setOpen(true)
          e.target.reset()
        } else {
          setMessage(`Assignment [${assignmentId}] is already attached to the Lesson [${props.lessonId}]`)
          setOpen(true)
        }
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        setMessage(response.data.result.message)
        console.error(response.data.result)
        setOpen(true)
      }
    })
    return false
  }

  if (props.lessonId) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="overline" gutterBottom>
              Attach Assignment to the Lesson
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <form onSubmit={submitForm} method="post">
              <Stack direction="row" spacing={2}>
                <TextField
                  type="number"
                  id="assignment-id"
                  label="AssignmentID"
                  variant="standard"
                  value={assignmentId}
                  onChange={(e) => { setAssignmentId(e.target.value) }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  onClick={submitForm}
                  disabled={!assignmentId}
                >
                  Attach
                </Button>
              </Stack>
            </form>
          </Grid>
        </Grid>

        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          autoHideDuration={2000}
          onClose={handleClose}
          action={action}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Box>
    )
  } else {
    return (
      <Stack spacing={1}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="text" sx={{ fontSize: '1.1rem' }} />
        {/* For other variants, adjust the size with `width` and `height` */}
        <Stack direction="row" spacing={2}>
          <Skeleton variant="rectangular" width={200} height={40} />
          <Skeleton variant="rectangular" width={200} height={40} />
        </Stack>
      </Stack>
    )
  }
}

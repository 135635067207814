import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import rehypeAudio from 'lib/rehype-audio'

export default function Markdown({ children, components }) {
  return <ReactMarkdown
      rehypePlugins={[rehypeRaw, rehypeAudio]}
      plugins={[remarkGfm]}
      children={ children }
      components={{ ...components }}
    />
}

import { useState, useEffect } from 'react'

import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import PersonIcon from '@mui/icons-material/Person';
import Typography from '@mui/material/Typography';

import Teacher from 'components/users/Teacher'

import rpc, { buildRequest } from 'lib/rpc'

export default function TeacherSelector(props) {
  const [open, setOpen] = useState(props.open);
  const [teachers, setTeachers] = useState([])

  useEffect(() => {
    rpc.post(
      "",
      buildRequest(
        "admin.users.list",
        { query: { is_staff: true } },
      ),
    ).then(({ data, errors }) => {
      if (data.result) {
        setTeachers(data.result.data)
      }
    })
  }, [])

  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
    <>
      <Stack direction={'row'} spacing={2} flexGrow={1} alignItems={'center'} justifyContent={'space-between'}>
        {props.teacher && (
          <Teacher {...props.teacher} />
        )}
        <Button
          sx={{ flexShrink: 1 }}
          onClick={() => setOpen(true)}
          variant="outlined"
          startIcon={<PersonIcon />}
        >Choose...</Button>
      </Stack>

      <TeacherSelectorDialog
        open={open}
        teachers={teachers}
        selectedValue={props.teacher}
        onClose={teacher => { props.setTeacher(teacher); setOpen(false) }}
      />
    </>
  )
}


function TeacherSelectorDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Assign teacher to the group</DialogTitle>
      <List sx={{ pt: 0 }}>
        {props.teachers ? props.teachers.map((teacher) => (
          <ListItem disableGutters key={`teacher_${teacher.id}`}>
            <ListItemButton onClick={() => handleListItemClick(teacher)} key={teacher.id}>
              <ListItemAvatar>
                <Avatar src={teacher.photo} />
              </ListItemAvatar>
              <ListItemText primary={`${teacher.first_name} ${teacher.last_name}`} secondary={teacher.username} />
            </ListItemButton>
          </ListItem>
        ))
          : <Typography variant="body1" color={'error'} textAlign={'center'}>No teachers found</Typography>
        }
      </List>
    </Dialog>
  );
}
import React, { useState } from 'react'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'

import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import SendIcon from '@mui/icons-material/Send'

export default function Search(props) {
  const [value, setValue] = useState(props.value)

  const clear = () => {
    setValue("")
    props.setSearchString("")
  }

  const keyDownHandler = (e) => {
    if (e.key === 'Enter') {
      props.setSearchString(value)
      if (props.setPage) {
        props.setPage(1)
      }
    }
    if (e.key === 'Escape') {
      clear()
    }
  }

  return (
    <Input
      padding={2}
      variant="outlined"
      value={value}
      onChange={(e) => { setValue(e.target.value) }}
      placeholder={'Search...'}
      disabled={props.disabled}
      fullWidth={true}
      onKeyDown={keyDownHandler}
      startAdornment={
        <InputAdornment position="start" style={{ cursor: 'pointer' }}>
          {value.length === 0 ? <SearchIcon /> : <ClearIcon onClick={clear} />}
        </InputAdornment>
      }
      endAdornment={
        value.length > 0 &&
        <InputAdornment position="end" style={{ cursor: 'pointer' }}>
          <SendIcon onClick={(e) => props.setSearchString(e.target.value)} />
        </InputAdornment>
      }
      className={props.className}
    />
  )
}
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'

import AssignmentTypeIcon from 'components/elements/AssignmentTypeIcon'
import { TEMPLATES } from './templates'

const STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  minWidth: 300,
  maxHeight: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

export default function TemplateChooserModal({ open, setOpen, setContent, ...props }) {

  const handleSetContent = (content) => {
    setContent(content)
    setOpen(false)
  }

  const handleClose = (event, reason) => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={STYLE}>
        <Typography variant="h5">
          Choose an Assignment type to start with a template:
        </Typography>
        <Grid container spacing={2}>
          {localStorage.getItem('crAssignment_source_yaml') && (
            <Grid item xs={3}>
              <AssignmentTemplate
                fullType="From Draft"
                type="draft"
                content={localStorage.getItem('crAssignment_source_yaml')}
                handleSetContent={() => setOpen(false)}
              />
            </Grid>
          )}
          {TEMPLATES.map((template) =>
            <Grid item xs={3} key={template.fullType}>
              <AssignmentTemplate
                {...template}
                handleSetContent={handleSetContent}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  )
}

export function AssignmentTemplate(props) {
  return (
    <Paper sx={{ padding: 1, cursor: 'pointer' }} onClick={() => props.handleSetContent(props.content)}>
      <Stack spacing={2}>
        <Stack spacing={2} direction="row">
          <AssignmentTypeIcon
            type={props.type}
            fullType={props.fullType}
          />
          <Typography variant="overline">
            {props.fullType}
          </Typography>
        </Stack>
        <pre style={{ fontSize: 6, overflow: 'hidden', height: 135 }}>
          {props.content}
        </pre>
        <Button variant="contained" onClick={() => props.handleSetContent(props.content)}>
          Use this template
        </Button>
      </Stack>
    </Paper>
  )
}
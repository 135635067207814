// Generates an assignment YAML of type v2_input
// with words and their translations
// converts a text like:
// word - переклад
// word - переклад
//
// to:
// version: 2
// type: wordcards
// name: Name cards
// level: a1
// questions:
//   - [[[word]]]
//   - [[[word]]]

export function wordWordcardsGenerator(rawText, topic, level, limit, shuffle = true) {
  const lines = rawText.split('\n')

  let words = lines.map((line) => {
    const [word,] = line.split(' - ')
    return `[[[${word}]]]` || null
  }).filter((w) => w)

  // shuffle words
  if (shuffle) {
    for (let i = words.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [words[i], words[j]] = [words[j], words[i]]
    }
  }

  const content = `version: 2
type: wordcards
name: ${topic} cards
level: ${level}
questions:
  - ${words.slice(0, limit || words.length).join('\n  - ')}
`
  return content
}
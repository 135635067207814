import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


export default function AssignmentAudio(props) {
  return (
    <ImageListItem>
      <img
        src="/audio.png"
        alt={props.link}
      />
      <ImageListItemBar
        title="Copy Audio link"
        subtitle={props.link}
        actionIcon={
          <Stack direction="row">
            <CopyToClipboard text={`${props.link}`}>
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label="copy tag"
              >
                <ContentCopyIcon />
              </IconButton>
            </CopyToClipboard>

            {props.removeFile &&
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label="delte audio"
                onClick={(e) => { props.removeFile(props.index) }}
              >
                <DeleteForeverIcon />
              </IconButton>
            }
          </Stack>
        }
      />
    </ImageListItem>
  )
}

import React, { useState, useEffect } from 'react'

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import rpc, { buildRequest } from 'lib/rpc'

export default function LoginForm() {
  const [phone, setPhone] = useState()
  const [password, setPassword] = useState()
  const [isPhoneValid, setIsPhoneValid] = useState()
  const [isPasswordValid, setIsPasswordValid] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    if (phone && phone.length > 0 && phone.replace(/\D/g, '').length === 10) {
      setIsPhoneValid(true)
    } else {
      setIsPhoneValid(false)
    }

    if (password && password.length > 0) {
      setTouched(true)
    } else {
      setTouched(false)
    }

    if (password && password.length > 4) {
      setIsPasswordValid(true)
    } else {
      setIsPasswordValid(false)
    }
  }, [phone, password]);

  const exchangeCredentialsForToken = (phone, password) => {
    setErrorMessage(null)

    rpc.post(
      '',
      buildRequest('users.admin.login',
        {
          phone: phone.replace(/\D/g, ''),
          password,
        })
    ).then(response => {
      if (response.data.result.key) {
        window.localStorage.setItem("accessKey", response.data.result.key)
        window.location.replace('/')
      } else {
        setErrorMessage(response.data.result.message)
        setIsPasswordValid(false)
      }
    })
      .catch(error => {
        setErrorMessage("Connection problems")
        console.error(error)
      });
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (isPhoneValid) {
      exchangeCredentialsForToken(phone, password);
    }
    return false
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Paper sx={{ padding: 3 }}>
        <form onSubmit={submitForm}>
          <Stack spacing={1}>
            {errorMessage &&
              <Alert severity="error">{errorMessage}</Alert>
            }
            <TextField
              type="text"
              id="phone"
              label="Login"
              variant="standard"
              onChange={(e) => setPhone(e.target.value)}
            />

            <TextField
              type="password"
              id="pass"
              label="Password"
              variant="standard"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              type="submit"
              variant="contained"
            >
              Login
            </Button>
          </Stack>
        </form>
      </Paper>
    </Box>
  )
}

import React, { useEffect, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

import rpc, { buildRequest } from 'lib/rpc';


export default function HashtagField(props) {

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [searchTerm, setSearchTerm] = useState("")

  useEffect(() => {
    setOptions([])

    if (searchTerm.length > 2) {
      setLoading(true)

      rpc.post(
        "",
        buildRequest(
          "admin.assignments.hashtag_search",
          {
            // hashtags on the backend is stored without the leading #
            search: searchTerm.startsWith("#") ? searchTerm.slice(1) : searchTerm,
          },
        )
      ).then(response => {
        if (!response.data.result.message) {
          setOptions(response.data.result.map((hashtag) => `#${hashtag.name}`))
        } else {
          console.error(response.data)
        }
        setLoading(false)
      })
    }
  }, [searchTerm])

  return (
    <Autocomplete
      multiple
      freeSolo
      id="hashtags"
      loading={loading}
      options={options}
      onChange={props.setHashtags}
      value={props.defaultValue || []}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Hashtags"
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Hashtags"
        />
      )}
    />
  )
}
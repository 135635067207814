import axios from 'axios'
import { API_URL } from 'config'

function rpc () {
  let headers = {
    'Content-Type': 'application/json',
  }
  const instance = axios.create({
    baseURL: `${API_URL}`,
    headers: headers,
  })

  if (typeof document !== 'undefined') {
    const accessKey = localStorage.getItem("accessKey");
    if (accessKey !== '') {
      instance.defaults.headers.common['Authorization'] = `Token ${accessKey}`
    }
  }
  return instance
}

export function buildRequest(method, params) {
  return {
    jsonrpc: "2.0",
    id: "dontcare",
    method,
    params,
  }
}

export function signoutAndRedirectToIndex() {
  if (typeof document !== 'undefined') {
    window.localStorage.removeItem("accessKey")
    window.location.replace("/")
  }
}

export default rpc()

import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography';
import VideocamIcon from '@mui/icons-material/Videocam';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import Teacher from 'components/users/Teacher'
import Student from 'components/users/Student'
import Pagination from 'components/elements/Pagination';

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

export default function GroupsPage() {
  const theme = useContext(ThemeContext)

  let { page = 1 } = useParams()
  const navigate = useNavigate()

  const [groups, setGroups] = useState()
  const [perPage, setPerPage] = useState(15)
  const [total, setTotal] = useState(0)

  const [showAll, setShowAll] = useState(localStorage.getItem("groups_showAll") === "true")

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // when `showAll` changes we need to re-request groups list
    localStorage.setItem("groups_showAll", showAll)
    setGroups(null)
    rpc.post(
      "",
      buildRequest(
        "admin.groups.list",
        {
          show_all: showAll,
          page: page,
          per_page: perPage,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setGroups(response.data.result.data)
        setTotal(response.data.result.records)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })

  }, [showAll, page, perPage])

  const setPage = (newPage) => {
    navigate(`/groups/${newPage}`, { replace: true })
  }

  return (
    <>
      <Helmet>
        <title>Groups</title>
      </Helmet>

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        component={RouterLink} to="/groups/create"
      >
        <AddIcon />
      </Fab>

      <Container fixed sx={{ paddingBottom: 7 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3" gutterBottom>
              Groups
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="secondary">Only my Groups</Typography>
              <Switch
                checked={!showAll}
                aria-label="Only my Groups"
                onChange={(e) => { setShowAll(!e.target.checked) }}
              />
            </Stack>
          </Stack>

          <Paper>
            {!groups &&
              <CircularProgress />
            }
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Teacher</TableCell>
                    <TableCell align="left">Start date</TableCell>
                    <TableCell align="left">Students</TableCell>
                    <TableCell align="left">Zoom</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groups && groups.map(group =>
                    <TableRow key={`group-${group.id}`}>
                      <TableCell>
                        <Link to={`/groups/details/${group.id}`} component={RouterLink}>
                          {group.id}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link to={`/groups/details/${group.id}`} component={RouterLink}>
                          {group.name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Teacher {...group.teacher} />
                      </TableCell>
                      <TableCell>
                        {group.start_date}
                      </TableCell>
                      <TableCell>
                        <ol>
                          {group.students && group.students.map(student =>
                            <li key={`group-${group.id}__student-${student.id}`}>
                              <Student {...student} />
                            </li>
                          )}
                        </ol>
                      </TableCell>
                      <TableCell>
                        {group.conference_link &&
                          <RouterLink
                            to={group.conference_link}
                            target="_blank"
                          >
                            <Button
                              variant="outlined"
                            >
                              <VideocamIcon /> ZOOM
                            </Button>
                          </RouterLink>
                        }
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <Pagination
                  page={page}
                  total={total}
                  perPage={perPage}
                  setPage={setPage}
                  setPerPage={setPerPage}
                />
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
    </>
  )
}

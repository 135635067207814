// Generates an assignment YAML of type v2_radio
// with words and their translations
// converts a text like:
// word - переклад
// word - переклад
//
// to:
// version: 2
// type: radio
// name: Topic to ukr choice
// level: A2
// meta:
//   text: Find the translation
//   translation: Знайди переклад
// questions:
//   - meta:
//       assignment: |
//         word
//     variants:
//       - переклад
//       - ще один переклад, або не один через кому
//       - інший переклад
//     correct: 0
//   - meta:
//       assignment: |
//         word
//     variants:
//       - ще один переклад, або не один через кому
//       - переклад
//       - інший переклад
//     correct: 1
//
export function wordRadioGenerator(rawText, topic, levelString, limit, reverse = false) {
  const level = levelString.toUpperCase()
  const lines = rawText.split('\n')
  let words = lines.map((line) => {
    const [word, translation] = line.split(' - ')
    if (!word || !translation) {
      return null
    }
    if (reverse) {
      return { word: translation, translation: word }
    } else {
      return { word, translation }
    }
  }).filter((w) => w)
  // shuffle words
  for (let i = words.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [words[i], words[j]] = [words[j], words[i]]
  }
  const questions = words.slice(0, limit || words.length).map((word) => {
    // exclude correct answer from variants
    let variants = words.filter((w) => w.word !== word.word).map((w) => w.translation)
    // shuffle all variants to take 2 random
    for (let i = variants.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [variants[i], variants[j]] = [variants[j], variants[i]]
    }
    // taking 2
    variants = variants.slice(0, 2)
    // injecting the correct
    variants.push(word.translation)
    // shuffle 3 variants
    for (let i = variants.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [variants[i], variants[j]] = [variants[j], variants[i]]
    }
    const correct = variants.indexOf(word.translation)
    return {
      meta: {
        assignment: word.word,
      },
      variants,
      correct,
    }
  })
  const content = `version: 2
type: radio
name: ${topic} to ${!reverse ? 'urk' : 'eng'} choice
level: ${level}
meta:
  text: Find the translation
  translation: Знайди переклад
questions:
${questions ? questions.map((q) => `  - meta:
      assignment: |
        ${q.meta.assignment}
    variants: ${q.variants.map((v) => `\n      - ${v}`).join('')}
    correct: ${q.correct}
`).join('') : ''}

`
  return content
}
import React from 'react'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export default function Student(props) {
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 30,
        height: 30,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1] && name.split(' ')[1][0]}`,
    };
  }

  return (
    <Stack direction="row" spacing={1}>
      <Avatar
        alt={props.name}
        {...stringAvatar(props.name)}
      >
        {props.name[0]}
      </Avatar>
      <div>{props.name}</div>
    </Stack>
  )
}

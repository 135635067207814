import React from 'react'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'

import { stringToColor } from 'lib/string-to-color'

export default function Teacher(props) {
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 30,
        height: 30,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <Stack direction="row" spacing={1}>
      <Avatar
        alt={`${props.first_name} ${props.last_name}`}
        src={props.photo}
        {...stringAvatar(`${props.first_name} ${props.last_name}`)}
      >
        {props.first_name[0]}{props.last_name[0]}
      </Avatar>
      <div>{props.first_name} {props.last_name}</div>
    </Stack>
  )
}

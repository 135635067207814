import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Stack from '@mui/material/Stack'

import ModeEditIcon from '@mui/icons-material/ModeEdit'
import AddIcon from '@mui/icons-material/Add'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DangerousIcon from '@mui/icons-material/Dangerous'

import Pagination from 'components/elements/Pagination'
import Search from 'components/forms/Search'

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

export default function UsersPage() {
  const theme = useContext(ThemeContext)

  let { page = 1 } = useParams()
  const navigate = useNavigate()

  const [users, setUsers] = useState(null)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [searchString, setSearchString] = useState("")

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setUsers(null)

    rpc.post("", buildRequest("admin.users.list", { page, per_page: perPage, search: searchString }))
      .then(({ data, errors }) => {
        if (data.result) {
          setUsers(data.result.data)
          setTotal(data.result.records)
        } else {
          if (data.error.code === 5) {
            signoutAndRedirectToIndex()
          }
        }
      })
  }, [page, perPage, searchString])

  const setPage = (page) => {
    navigate(`/users/${page}`)
  }

  const roleColor = (role) => {
    switch (role) {
      case "superadmin":
      case "superuser":
        return "error"
      case "teacher":
        return "warning"
      case "student":
      default:
        return "success"
    }
  }

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        component={RouterLink} to="/users/create"
      >
        <AddIcon />
      </Fab>

      <Container fixed sx={{ paddingBottom: 7 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={'space-between'}
                flexWrap={'wrap'}
              >
                <Typography variant="h3" gutterBottom>
                  Users
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Paper padding={5}>
                <Search value={searchString} setSearchString={setSearchString} setPage={setPage} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <TableContainer>
                  <Table size="small" stickyHeader aria-label="sticky table">
                    <Pagination
                      total={total}
                      page={page}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                      container={TableHead}
                    />
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="left">Phone</TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="left">Name</TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="left">Active</TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="left">Role</TableCell>
                      </TableRow>
                      {users ?
                        users.map((user) => (
                          <TableRow
                            key={user.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              <Link to={`/users/details/${user.id}`} component={RouterLink}>{user.id}</Link>
                            </TableCell>
                            <TableCell align="left">
                              <Link to={`/users/details/${user.id}`} component={RouterLink}><ModeEditIcon /> {user.phone}</Link>
                            </TableCell>
                            <TableCell align="left">{user.first_name} {user.last_name}</TableCell>
                            <TableCell align="left">
                              {user.is_active ? <CheckCircleIcon color="success" /> : <DangerousIcon color="error" />}
                            </TableCell>
                            <TableCell align="left">
                              <Chip label={user.role} color={roleColor(user.role)} />

                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell colSpan={5}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      }
                    </TableBody>
                    <Pagination
                      total={total}
                      page={page}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                    />
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container >
    </>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { Helmet } from 'react-helmet-async'

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';

import TextField from '@mui/material/TextField';

import LessonsForm from 'components/forms/Lessons'
import TeacherSelector from 'components/forms/TeacherSelector';

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

export default function GroupsCreatePage() {
  const theme = useContext(ThemeContext)

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [fabColor, setFabColor] = useState("primary")
  const [highlightErrors, setHighlightErrors] = useState(false)
  const [open, setOpen] = useState(false)

  const [teacher, setTeacher] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [name, setName] = useState("")
  const [lessons, setLessons] = useState([])

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createGroup = () => {
    setLoading(true)
    if (!teacher) {
      setOpen(true)
      setLoading(false)
      return;
    }

    if (!name || !startDate || !lessons) {
      setLoading(false)
      setHighlightErrors(true)
      if (!lessons || lessons.length === 0) {
        setErrorMessage("Lessons must be planned in order to create a group")
      }
      return;
    }

    rpc.post("", buildRequest("admin.groups.create", {
      name,
      teacher_id: teacher.id,
      start_date: startDate.format("DD.MM.YYYY")
    })
    ).then(({ data, errors }) => {
      if (!data.result.code) {
        enqueueSnackbar("Group created successfully", { variant: "success" })
        const group_id = data.result.id
        rpc.post("", buildRequest("admin.groups.add_lessons_bulk", {
          group_id,
          lessons: lessons.map(lesson => ({ ...lesson, scheduled_on: lesson.scheduled_on.tz("Europe/Kyiv").format("YYYY-MM-DD+HH:mm:ss") })),
        })
        ).then(({ data, errors }) => {
          if (!data.result.code) {
            enqueueSnackbar("Lessons added successfully", { variant: "success" })
            setLoading(false)
            window.location.href = "/groups/details/" + group_id
          } else {
            enqueueSnackbar(`Error adding lessons: ${data.result.message}`, { variant: "error" })
            setLoading(false)
            setErrorMessage(errors[0].message)
            setFabColor("error")
            setTimeout(() => {
              setFabColor("primary")
            }, 1000)
          }
        })
      } else {
        if (data.error.code === 5) {
          signoutAndRedirectToIndex()
        }
        enqueueSnackbar(`Error creating group: ${data.result.message}`, { variant: "error" })
        setLoading(false)
        setErrorMessage(errors[0].message)
        setFabColor("error")
        setTimeout(() => {
          setFabColor("primary")
        }, 1000)
      }
    })
  }

  return (
    <>
      <Helmet>
        <title>Create Group</title>
      </Helmet>

      <Fab
        color={fabColor}
        aria-label="save"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={createGroup}
      >
        {loading ? <CircularProgress color="inherit" /> : <SaveIcon />}
      </Fab>

      <Container fixed>
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3" gutterBottom>
              Create Group
            </Typography>
          </Stack>
        </Box>

        <Grid container spacing={2}>

          {errorMessage && (
            <Grid item xs={12} s>
              <Alert severity="error">
                {errorMessage}
              </Alert>
            </Grid>
          )}

          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 2 }}>
              <Stack spacing={2} flexGrow={1}>
                <Typography variant="h5" gutterBottom>
                  Group Details
                </Typography>
                <TextField
                  fullWidth
                  error={highlightErrors && !name}
                  helperText={highlightErrors && !name && "Name is required"}
                  id="name"
                  label="Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Stack>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 2 }}>
              <Stack spacing={1} flexGrow={1}>
                <Typography variant="h5" gutterBottom>
                  Teacher
                </Typography>
                <TeacherSelector setTeacher={setTeacher} teacher={teacher} open={open} />
                <Typography variant="h5" gutterBottom color={(highlightErrors && !startDate) ? 'error' : 'text.primary'}>
                  Start date:
                </Typography>
                <DatePicker
                  error={highlightErrors && !startDate}
                  helperText={highlightErrors && !startDate && "Start date is required"}
                  format="DD.MM.YYYY"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </Stack>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <LessonsForm
              startDate={startDate}
              startNumber={0}
              attachLessons={setLessons}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

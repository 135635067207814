import React, { useState, useEffect } from 'react';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';

export default function LessonsForm(props) {

  const [numOfLessons, setNumOfLessons] = useState(32)
  const [startDate, setStartDate] = useState(props.startDate)
  const [lessons, setLessons] = useState([])

  const [weekDays, setWeekDays] = useState({
    monday: {
      number: 1,
      checked: false,
      time: null,
    },
    tuesday: {
      number: 2,
      checked: false,
      time: null,
    },
    wednesday: {
      number: 3,
      checked: false,
      time: null,
    },
    thursday: {
      number: 4,
      checked: false,
      time: null,
    },
    friday: {
      number: 5,
      checked: false,
      time: null,
    },
    saturday: {
      number: 6,
      checked: false,
      time: null,
    },
    sunday: {
      number: 0,
      checked: false,
      time: null,
    },
  })

  useEffect(() => {
    if (props.startDate) {
      setStartDate(props.startDate)
    }
  }, [props.startDate])

  const calculateLessons = () => {
    if (startDate) {
      let newLessons = []
      const lessonDays = Object.keys(weekDays)
        .map((day) => weekDays[day].checked === true && weekDays[day].time ? weekDays[day] : null)
        .filter(v => v !== null)

      if (lessonDays.length > 0) {
        let iteratorDate = startDate.clone()

        while (newLessons.length < numOfLessons) {
          const lessonDay = lessonDays.find(v => v.number === iteratorDate.day())
          if (lessonDay) {
            const lessonDate = iteratorDate.clone()
            lessonDate.set('hour', lessonDay.time.hour())
            lessonDate.set('minute', lessonDay.time.minute())
            lessonDate.set('second', 0)
            lessonDate.set('millisecond', 0)
            const lesson = {
              number: newLessons.length + 1 + (props.startNumber || 0),
              scheduled_on: lessonDate,

            }
            newLessons.push(lesson)
          }
          iteratorDate.add(1, 'd')
        }
        setLessons(newLessons)
        if (props.attachLessons) {
          props.attachLessons(newLessons)
        }
      }
    }
  }

  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Stack spacing={1} flexGrow={1}>
        <Typography variant="h5" gutterBottom>
          Lessons
        </Typography>
        <Stack spacing={2} flexGrow={1}>
          <TextField
            fullWidth
            value={numOfLessons}
            onChange={(e) => setNumOfLessons(e.target.value)}
            id="number"
            label="Number of lessons"
            helperText={"Number of lessons to create for the group"}
            variant="outlined"
            type="number"
          />
          <DatePicker
            format="DD.MM.YYYY"
            value={startDate}
            onChange={(date) => setStartDate(date)}
          />
          {Object.keys(weekDays).map((day) => (
            <React.Fragment key={day}>
              <Stack
                direction={'row'}
                spacing={2}
                flexGrow={1}
                alignItems={'top'}
              >
                <Stack
                  direction={'row'}
                  spacing={2}
                  flexGrow={1}
                  alignItems={'center'}
                >
                  <Typography
                    variant="outlined"
                    sx={{ width: 100 }}
                    color={day === 'sunday' ? 'secondary' : 'text.primary'}
                  >
                    {day.toUpperCase()}
                  </Typography>
                  <Checkbox
                    color={day === 'sunday' ? 'secondary' : 'primary'}
                    checked={weekDays[day].checked}
                    onChange={(e) => {
                      setWeekDays({
                        ...weekDays,
                        [day]: {
                          ...weekDays[day],
                          checked: e.target.checked,
                        }
                      })
                    }}
                  />
                  <TimePicker
                    ampm={false}
                    value={weekDays[day].time}
                    onChange={(time) => {
                      setWeekDays({
                        ...weekDays,
                        [day]: {
                          ...weekDays[day],
                          time: time,
                        }
                      })
                    }}
                  />
                </Stack>

                <Stack spacing={1} flexGrow={1} alignItems={'top'}>
                  {lessons.filter(v => v.scheduled_on.day() === weekDays[day].number).map((lesson) => (
                    <Typography key={`lesson_${lesson.number}`} variant="caption">
                      {lesson.number}. {lesson.scheduled_on.format('DD MMM YYYY HH:mm')}
                    </Typography>
                  ))}
                </Stack>
              </Stack>

              <Divider flexItem />
            </React.Fragment>
          ))}
        </Stack>
        <Button onClick={calculateLessons}>Plan lessons</Button>
      </Stack>
    </Paper>
  )
}
import axios from 'axios'
import { VIDEO_API_URL } from 'config'

function videoApi() {
  let headers = {
    'Content-Type': 'application/json',
  }
  const instance = axios.create({
    baseURL: `${VIDEO_API_URL}`,
    headers: headers,
  })

  if (typeof document !== 'undefined') {
    const accessKey = localStorage.getItem("accessKey");
    if (accessKey !== '') {
      instance.defaults.headers.common['Authorization'] = `Token ${accessKey}`
    }
  }
  return instance
}

export function signoutAndRedirectToIndex() {
  if (typeof document !== 'undefined') {
    window.localStorage.removeItem("accessKey")
    window.location.replace("/")
  }
}

export default videoApi()

import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDropzone } from 'react-dropzone'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import PostAddIcon from '@mui/icons-material/PostAdd'
import Container from '@mui/material/Container'
import ImageList from '@mui/material/ImageList'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import Editor from 'components/elements/Editor'
import AssignmentImage from 'components/elements/AssignmentImage'
import AssignmentAudio from 'components/elements/AssignmentAudio'
import HashtagField from 'components/forms/HashtagField'
import TemplateChooserModal from './TemplatesChooserModal'

import SaveIcon from '@mui/icons-material/Save'

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'
import { CDN_URL } from 'config'

export default function AssignmentCreatePage() {
  const theme = useContext(ThemeContext)
  const navigate = useNavigate()

  const [assignment, setAssignment] = useState({
    name: localStorage.getItem('crAssignment_name') || "",
    kind: localStorage.getItem('crAssignment_kind') || "",
    assignment_level: localStorage.getItem('crAssignment_level') || "a1",
    source_yaml: localStorage.getItem('crAssignment_source_yaml') || "",
  })
  const [files, setFiles] = useState([])
  const [audio, setAudio] = useState([])
  const [templatesModalOpen, setTemplatesModalOpen] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
      'audio/*': ['.mp3'],
    },
    onDrop: acceptedFiles => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          setFiles(
            [...files,
            ...acceptedFiles.filter(file => file.type.includes('image')).map(file => {
              const filename = `${uuid()}.${file.name.split('.').pop()}`
              return Object.assign(file, {
                filename: filename,
                preview: URL.createObjectURL(file),
                base64Image: e.target.result,
                link: `${CDN_URL}/media/assignments/${filename}`,
              })
            })
            ])

          setAudio(
            [...audio,
            ...acceptedFiles.filter(file => file.type.includes('audio')).map(file => {
              const filename = `${uuid()}.${file.name.split('.').pop()}`
              return Object.assign(file, {
                filename: filename,
                link: `https://cdn.icepig.online/media/audio/${filename}`,
                base64audio: e.target.result,
              })
            })
            ])
        }
        reader.readAsDataURL(file)
      })
    }
  })

  const setHashtags = (e, newValue) => {
    const newHashtags = newValue.map(hashtag => {
      if (!hashtag.startsWith('#')) {
        return `#${hashtag}`
      } else {
        return hashtag
      }
    })
    setAssignment({ ...assignment, hashtags: newHashtags.join(' ') })
  }

  // Keep the draft in localStorage
  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    localStorage.setItem('crAssignment_name', assignment.name)
    localStorage.setItem('crAssignment_kind', assignment.kind)
    localStorage.setItem('crAssignment_level', assignment.assignment_level)
    localStorage.setItem('crAssignment_source_yaml', assignment.source_yaml)
  }, [assignment])

  useEffect(() => {
    const newAssignmentSourceYaml = assignment.source_yaml
      .replace(/name:\s(.+)/g, `name: ${assignment.name}`)
      .replace(/level:\s(.+)/g, `level: ${assignment.assignment_level}`)
    setAssignment({ ...assignment, source_yaml: newAssignmentSourceYaml })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignment.name, assignment.assignment_level])

  const removeFile = (index) => {
    URL.revokeObjectURL(files[index].preview)
    setFiles(files.filter((file, i) => i !== index))
  }

  const removeAudio = (index) => {
    setAudio(audio.filter((file, i) => i !== index))
  }

  const thumbs = files.map((file, index) => (
    <AssignmentImage
      key={`assignmentImageFile_${index}`}
      preview={file.preview}
      link={file.link}
      alt={file.name}
      index={index}
      removeFile={removeFile}
    />
  ))

  const audios = audio.map((file, index) => (
    <AssignmentAudio
      key={`assignmentAudioFile_${index}`}
      link={file.link}
      alt={file.name}
      index={index}
      removeFile={removeAudio}
    />
  ))

  const submitForm = (e) => {
    e.preventDefault()

    if ([
      assignment.name.length > 0,
      assignment.source_yaml.length > 0,
    ].some(x => x === false)) {
      return false
    }

    rpc.post(
      "",
      buildRequest(
        "admin.assignments.create",
        {
          name: assignment.name,
          kind: assignment.kind,
          hashtags: assignment.hashtags,
          assignment_level: assignment.assignment_level,
          source_yaml: assignment.source_yaml,
          images: files.map(file => ({
            name: file.filename,
            base64: file.base64Image
          })),
          audio: audio.map(file => ({
            name: file.filename,
            base64: file.base64audio,
          }))
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        localStorage.removeItem('crAssignment_name')
        localStorage.removeItem('crAssignment_kind')
        localStorage.removeItem('crAssignment_level')
        localStorage.removeItem('crAssignment_source_yaml')
        navigate(`/assignments/details/${response.data.result.id}`)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        setErrorMessage(response.data.result.message)
      }
    })
    return false
  }

  return (
    <>
      <Helmet>
        <title>Edit Assignment</title>
      </Helmet>
      <Fab
        color="primary"
        aria-label="save"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        disabled={[
          assignment.name.length > 0,
          assignment.source_yaml.length > 0,
        ].some(x => x === false)}
        onClick={submitForm}
      >
        <SaveIcon />
      </Fab>

      <Container fixed>
        <form onSubmit={submitForm} method="post">
          <Box sx={{ flexGrow: 1, marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant="h4" component="h1" gutterBottom>
                    New Assignment
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={(e) => setTemplatesModalOpen(true)}
                  >
                    <PostAddIcon />
                  </Button>
                  <TemplateChooserModal
                    open={templatesModalOpen}
                    setOpen={setTemplatesModalOpen}
                    setContent={(content) => setAssignment({ ...assignment, source_yaml: content })}
                  />
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Paper>
                      {assignment ?
                        <Stack padding={1} spacing={1}>
                          <TextField
                            label="Name"
                            error={assignment.name.length === 0}
                            helperText={assignment.name.length > 0 ? "" : "Name is required"}
                            value={assignment.name}
                            onChange={(e) => setAssignment({ ...assignment, name: e.target.value })}
                          />
                          <Select
                            labelId="level-select-label"
                            id="level-select"
                            value={assignment.assignment_level}
                            label="Level"
                            onChange={(e) => setAssignment({ ...assignment, assignment_level: e.target.value })}
                          >
                            <MenuItem value={"a0"}>A0</MenuItem>
                            <MenuItem value={"a1"}>A1</MenuItem>
                            <MenuItem value={"a1plus"}>A1+</MenuItem>
                            <MenuItem value={"a2"}>A2</MenuItem>
                            <MenuItem value={"b1"}>B1</MenuItem>
                            <MenuItem value={"b2"}>B2</MenuItem>
                            <MenuItem value={"c1"}>C1</MenuItem>
                          </Select>
                          <TextField
                            label="Kind"
                            value={assignment.kind}
                            onChange={(e) => setAssignment({ ...assignment, kind: e.target.value })}
                            helperText="Prepositions, collocations, etc."
                          />
                          <HashtagField
                            defaultValue={assignment.hashtags ? assignment.hashtags.split(' ') : []}
                            setHashtags={setHashtags}
                          />
                        </Stack>
                        : <CircularProgress />
                      }
                    </Paper>
                  </Grid>
                  {errorMessage &&
                    <Grid item xs={12}>
                      <Paper>
                        <Alert severity="error">{errorMessage}</Alert>
                      </Paper>
                    </Grid>
                  }
                  <Grid item xs={12} sx={{ marginTop: 2 }}>
                    <Paper>
                      {assignment ?
                        <Editor
                          value={assignment.source_yaml}
                          setValue={(newValue) => setAssignment({ ...assignment, source_yaml: newValue })}
                        />
                        :
                        <CircularProgress />
                      }
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Paper>
                      <Typography
                        variant="overline"
                        padding={1}
                        gutterBottom
                      >
                        Assignment Images & Audio
                      </Typography>
                      <div>
                        <div {...getRootProps({ className: 'dropzone' })}>
                          <input {...getInputProps()} />
                          <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                        <ImageList>
                          {thumbs}
                          {audios}
                        </ImageList>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </>
  )
}

import React from "react"
import { Editor as MocanoEditor } from '@monaco-editor/react'


export default function Editor({ defaultLanguage, defaultValue, value, setValue, readOnly, ...props }) {

  return (
    <MocanoEditor
      theme="vs-dark"
      height={props.height || "50vh"}
      defaultLanguage={defaultLanguage || "yaml"}
      defaultValue={defaultValue}
      value={value}
      onChange={(val, event) => setValue(val)}
      options={{
        renderFinalNewline: "on",
        renderWhitespace: "all",
        wordWrap: "on",
        readOnly: readOnly || false,
      }}
      {...props}
    />
  )
}

// Generates a theory with words
// converts a text like:
// word - переклад
// word - переклад
//
// to:
// version: 2
// type: theory
// name: Theory name theory
// level: A2
// content: |
//
//   1. **word** - переклад
//   1. **word** - переклад
//
//   [[[word]]]
//   [[[word]]]
//
export function wordTheoryGenerator(rawText, name, levelString, includeSimpleWords, includeWordTags) {
  let text = rawText.trim()

  let includeContent = ''
  if (includeSimpleWords) {
    includeContent += text.replace(/(.+) - (.+)/g, '  1. **$1** - $2')
  }
  if (includeWordTags) {
    if (includeSimpleWords) {
      includeContent += `

`
    }
    includeContent += text.replace(/(.+) - (.+)/g, '  [[[$1]]]')
  }
  const level = levelString.toUpperCase()
  const content = `version: 2
type: theory
name: ${name} theory
level: ${level}
content: |

${includeContent}
`
  return content
}
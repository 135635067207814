import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Stack from '@mui/material/Stack'

import ModeEditIcon from '@mui/icons-material/ModeEdit'
import AddIcon from '@mui/icons-material/Add'

import AssignmentTypeIcon from 'components/elements/AssignmentTypeIcon'
import Pagination from 'components/elements/Pagination'
import Search from 'components/forms/Search'

import AssignmentTypeFilter from './filters'

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

export default function AssignmentsPage() {
  const theme = useContext(ThemeContext)

  let { page = 1 } = useParams()
  const navigate = useNavigate()

  const [assignments, setAssignments] = useState(null)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [searchString, setSearchString] = useState("")
  const [assignmentTypeFilterValue, setAssignmentTypeFilterValue] = useState(null)

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setAssignments(null)

    rpc.post(
      "",
      buildRequest(
        "admin.assignments.list",
        {
          page: page,
          per_page: perPage,
          search: searchString,
          assignment_type: assignmentTypeFilterValue,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setAssignments(response.data.result.data)
        setTotal(response.data.result.records)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })
  }, [page, perPage, searchString, assignmentTypeFilterValue])

  const setPage = (page) => {
    navigate(`/assignments/${page}`)
  }

  return (
    <>
      <Helmet>
        <title>Assignments</title>
      </Helmet>

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        component={RouterLink} to="/assignments/create"
      >
        <AddIcon />
      </Fab>

      <Container fixed>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={'space-between'}
                flexWrap={'wrap'}
              >
                <Typography variant="h3" gutterBottom>
                  Assignments
                </Typography>
                <AssignmentTypeFilter
                  value={assignmentTypeFilterValue}
                  onChange={setAssignmentTypeFilterValue}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Paper padding={5}>
                <Search value={searchString} setSearchString={setSearchString} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <TableContainer>
                  <Table size="small" stickyHeader aria-label="sticky table">
                    <Pagination
                      total={total}
                      page={page}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                      container={TableHead}
                    />
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 700 }}>ID</TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="left">Name</TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="left">Level</TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="left">Type</TableCell>
                        <TableCell sx={{ fontWeight: 700 }} align="left">Kind</TableCell>
                      </TableRow>
                      {assignments ?
                        assignments.map((assignment) => (
                          <TableRow
                            key={assignment.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              <Link to={`/assignments/details/${assignment.id}`} component={RouterLink}>{assignment.id}</Link>
                            </TableCell>
                            <TableCell align="left">
                              <Stack spacing={1}>
                                <Link to={`/assignments/details/${assignment.id}`} component={RouterLink}><ModeEditIcon /> {assignment.name}</Link>
                                <Typography variant="caption" color="text.secondary">
                                  {assignment.hashtags}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{assignment.assignment_level.toUpperCase()}</TableCell>
                            <TableCell align="left">
                              <AssignmentTypeIcon
                                type={assignment.assignment_type}
                                fullType={assignment.full_type}
                              />
                            </TableCell>
                            <TableCell align="left">{assignment.kind}</TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell colSpan={5}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      }
                    </TableBody>
                    <Pagination
                      total={total}
                      page={page}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                    />
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container >
    </>
  )
}

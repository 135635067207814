import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Editor from 'components/elements/Editor'

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

export default function GeneratorVoicePage() {
  const theme = useContext(ThemeContext)

  const [text, setText] = useState()
  const [voice, setVoice] = useState("en-US-Standard-A")
  const [mp3, setMp3] = useState(null)

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitForm = (e) => {
    e.preventDefault()

    rpc.post(
      "",
      buildRequest(
        "admin.vocabulary.synt",
        {
          text,
          voice,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        if (response.data.result.mp3_base64) {
          setMp3(response.data.result.mp3_base64)
        } else {
          console.error("missing mp3_base64 in response")
        }
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })
    return false
  }

  const handleChange = (event) => {
    setVoice(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Generator Voice</title>
      </Helmet>
      <Container fixed>
        <form onSubmit={submitForm} method="post">
          <Box sx={{ flexGrow: 1, marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Voice Generator
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Paper>
                  <Editor
                    setValue={setText}
                  />
                </Paper>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={2}>
                  <Paper>
                    <Stack padding={2} spacing={1}>
                      <Typography variant="overline" component="p">
                        Voice Parameters
                      </Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={voice}
                        label="Voice"
                        onChange={handleChange}
                      >
                        <MenuItem value={"en-US-Standard-A"}>MALE_A</MenuItem>
                        <MenuItem value={"en-US-Standard-B"}>MALE_B</MenuItem>
                        <MenuItem value={"en-US-Standard-C"}>FEMALE_C</MenuItem>
                        <MenuItem value={"en-US-Standard-D"}>MALE_D</MenuItem>
                        <MenuItem value={"en-US-Standard-E"}>FEMALE_E</MenuItem>
                        <MenuItem value={"en-US-Standard-F"}>FEMALE_F</MenuItem>
                        <MenuItem value={"en-US-Standard-G"}>FEMALE_G</MenuItem>
                        <MenuItem value={"en-US-Standard-H"}>FEMALE_H</MenuItem>
                        <MenuItem value={"en-US-Standard-I"}>MALE_I</MenuItem>
                        <MenuItem value={"en-US-Standard-J"}>MALE_J</MenuItem>
                      </Select>
                    </Stack>
                  </Paper>
                  <Paper padding={2}>
                    {mp3 ?
                      <Stack padding={1} spacing={1}>
                        <Typography variant="body1" component="p">
                          {text}
                        </Typography>

                        <Stack>
                          <audio
                            controls
                            src={`data:audio/mp3;base64,${mp3}`}
                          />
                        </Stack>
                      </Stack>
                      : <Typography padding={2} variant="primary">Empty</Typography>}
                  </Paper>
                </Stack>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={submitForm}
                >
                  Syntethize audio
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import MuiAlert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import Snackbar from '@mui/material/Snackbar'

import Stack from '@mui/material/Stack'
import EventIcon from '@mui/icons-material/Event'

import Student from 'components/users/Student'
import AssignmentLink from 'components/elements/AssignmentLink'
import AssignmentResult from 'components/users/AssignmentResult'
import AttachAssignmentForm from 'components/forms/AttachAssignment'
import TheoryAssignmentForm from 'components/forms/TheoryAssignment'

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function LessonDetailsPage() {
  const theme = useContext(ThemeContext)

  let { groupId, lessonId } = useParams()
  const [lesson, setLesson] = useState()
  const [group, setGroup] = useState()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState("")

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page

    rpc.post(
      "",
      buildRequest(
        "admin.groups.get",
        { group_id: groupId },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setGroup(response.data.result)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })

    fetchLesson(lessonId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const fetchLesson = (lessonId) => {
    rpc.post(
      "",
      buildRequest(
        "admin.groups.lesson_details",
        { lesson_id: lessonId },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setLesson(response.data.result)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })
  }

  const detachAssignment = (lessonId, assignmentId) => {
    rpc.post(
      "",
      buildRequest(
        "admin.assignment.detach",
        {
          lesson_id: lessonId,
          assignment_id: assignmentId,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        if (response.data.result.success) {
          fetchLesson(lessonId)
          setMessage(`Assigment [${assignmentId}] is detached from the Lesson [${lessonId}]`)
          setOpen(true)
        }
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <>
      <Helmet>
        <title>Lesson Details {(lesson && lesson.number) ? `#${lesson.number}` : 'loading...'}</title>
      </Helmet>
      <Snackbar
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        autoHideDuration={2000}
        onClose={handleClose}
        action={action}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <Container fixed sx={{ paddingBottom: 3 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction={"row"} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                <Typography variant="h4" gutterBottom>
                  Lesson #{lesson && lesson.number}
                </Typography>
                <Typography variant="h4" gutterBottom>
                  {group && group.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="secondary" sx={{ verticalAlign: 'middle' }}>
                <EventIcon fontSize="small" /> {lesson && lesson.scheduled_on}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              {group ?
                <Paper sx={{ padding: 2 }}>
                  {group && group.students &&
                    <Stack direction="row" spacing={2}>
                      {group.students.map(student =>
                        <Student key={`student-${student.id}`} {...student} />
                      )}
                    </Stack>
                  }
                </Paper>
                :
                <CircularProgress />
              }
            </Grid>

            <Grid item xs={12}>
              <Paper>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Assignment</TableCell>
                        {group ? group.students && group.students.map(student =>
                          <TableCell key={`group-student-${student.id}`}>
                            <Student {...student} />
                          </TableCell>
                        )
                          :
                          <CircularProgress />
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lesson ? lesson.assignments.map(assignment =>
                        <TableRow key={`assignment-${assignment.id}`}>
                          <TableCell>
                            <Stack direction="row" spacing={2}>
                              <AssignmentLink {...assignment} />
                              <IconButton
                                aria-label="delete"
                                onClick={() => { detachAssignment(lesson.id, assignment.id) }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                          </TableCell>
                          {group.students.map(student =>
                            <TableCell key={`result-${assignment.id}__student-${student.id}`}>
                              {lesson.students_results[assignment.id] && lesson.students_results[assignment.id][student.id] && <AssignmentResult {...lesson.students_results[assignment.id][student.id]} />}
                            </TableCell>
                          )}
                        </TableRow>
                      )
                        :
                        <CircularProgress />
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ padding: 2 }}>
                <AttachAssignmentForm
                  lessonId={lesson && lesson.id}
                  refreshLesson={fetchLesson}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ padding: 2 }}>
                <TheoryAssignmentForm
                  lessonId={lesson && lesson.id}
                  refreshLesson={fetchLesson}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box >
      </Container >
    </>
  )
}

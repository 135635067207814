import React, { useState, useEffect, useRef } from "react"
import { Scheduler, MobileScheduler, useArrayState } from "@cubedoodl/react-simple-scheduler"

import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import Typography from "@mui/material/Typography"

import { stringToColor } from "lib/string-to-color"
import { Calendar } from "@cubedoodl/react-simple-scheduler"

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

// hook to get previous value of a state
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function Timeline(props) {
  const [selected, setSelected] = useState(new Date())
  const [lessons, setLessons,] = useArrayState([])

  const [showAll, setShowAll] = useState(localStorage.getItem("schedule_showAll") === "true")
  const [width, setWidth] = useState(window.innerWidth);

  const prev = usePrevious({ selected, showAll })


  useEffect(() => {
    fetchSchedule()
    function resize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("schedule_showAll", showAll)
    if ([
      // prev.selected has changed
      prev && (prev.selected && (prev.selected.getFullYear() === selected.getFullYear() && prev.selected.getMonth() === selected.getMonth())),
      // prev.showAll has changed
      prev && (prev.showAll !== showAll),
    ].some(c => c === true)) {
      fetchSchedule()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, showAll])

  const fetchSchedule = () => {
    rpc.post(
      "",
      buildRequest(
        "admin.lessons.schedule",
        {
          year: selected.getFullYear(),
          month: selected.getMonth() + 1,
          show_all: showAll,
        }
      )
    ).then(response => {
      if (!response.data.result.message) {
        setLessons(response.data.result.map(lesson => ({
          from: new Date(lesson.scheduled_on),
          to: new Date(new Date(lesson.scheduled_on).getTime() + (60 * 60 * 1000)),
          name: lesson.teacher.first_name,
          calendar: {
            name: lesson.group.name,
            enabled: true,
          },
          style: {
            color: "white",
            backgroundColor: stringToColor(lesson.teacher.first_name),
          }
        })))
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })
  }

  const onEditRequest = (evt) => {
    alert(`${evt.calendar.name} | ${evt.name} | ${evt.from} | ${evt.to}`)
  }

  return (
    <>
      {(width > 600) ? (
        <>
          <Stack direction="row" alignItems="center">
            <Typography variant="secondary">Only my Lessons</Typography>
            <Switch
              checked={!showAll}
              aria-label="Only my Lessons"
              onChange={(e) => { setShowAll(!e.target.checked) }}
            />
          </Stack>
          <Scheduler
            events={lessons}
            selected={selected}
            setSelected={setSelected}
            onRequestAdd={(evt) => { return false }}
            onRequestEdit={onEditRequest}
          />
        </>
      ) : (
        <Stack alignItems={"center"} style={{ width: '100%' }}>
          <Calendar
            selected={selected}
            setSelected={setSelected}
          />
          <Stack direction="row" alignItems="center">
            <Typography variant="secondary">Only my Lessons</Typography>
            <Switch
              checked={!showAll}
              aria-label="Only my Lessons"
              onChange={(e) => { setShowAll(!e.target.checked) }}
            />
          </Stack>
          <MobileScheduler
            events={lessons}
            selected={selected}
            setSelected={setSelected}
            onRequestAdd={(evt) => { return false }}
            onRequestEdit={onEditRequest}
          />
        </Stack>
      )}
    </>
  )
}
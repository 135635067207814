import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import LoginForm from 'components/forms/Login'

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import { ThemeContext } from 'components/common/App'

export default function LoginPage() {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.changeTheme("dark-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>Log in</title>
      </Helmet>
      <Container
        fixed
        sx={{
          height: '100%',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Stack
          sx={{ flexGrow: 1, width: '100%', height: '100%' }}
          alignItems="center"
          justifyContent="center"
        >
          <LoginForm />
        </Stack>
      </Container>
    </>
  )
}

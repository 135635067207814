import React from 'react';
import { Check, Clear } from '@mui/icons-material';
import {
  Box,
  Button,
  Typography,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
} from '@mui/material';

const AssignmentStatus = ({ hasErrors, message, tracing, emoji, score, answersData }) => {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const bgColor = () => {
    if (hasErrors) {
      return 'error.main';
    } else if (score) {
      return 'success.main';
    } else {
      return 'info.main';
    }
  }

  return (
    <React.Fragment>
      <Box sx={{
        bgcolor: bgColor(),
        cursor: 'pointer',
        color: 'white',
      }} onClick={handleClickOpen}>
        <Stack direction="row" spacing={1} alignItems="start">
          {hasErrors ?
            <>
              <Clear />
              <Typography variant="body1">
                Assignment check failed. {message}
              </Typography>
            </>
            :
            score ? (
              <>
                <Check />
                <Typography variant="body1">
                  {emoji} {score}% Assignment has no errors. {message}
                </Typography>
              </>
            )
              :
              <>
                <Check />
                <Typography variant="body1">
                  Save assignment to perform a check.
                </Typography>
              </>
          }
        </Stack>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Assignment Check data"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontSize: 12 }}
            component={'pre'}>
            {JSON.stringify(answersData, null, 2)}

            {hasErrors ? tracing : "\ntraceback data is not available"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AssignmentStatus;

import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { v4 as uuid } from 'uuid'
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem'

import SaveIcon from '@mui/icons-material/Save'
import TransformIcon from '@mui/icons-material/Transform'
import UndoIcon from '@mui/icons-material/Undo'

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

export default function WordDetailsPage() {
  const theme = useContext(ThemeContext)
  let { wordId } = useParams()

  const [word, setWord] = useState(null)
  const [translation, setTranslation] = useState(null)

  const [audioUrl, setAudioUrl] = useState(null)
  const [voice, setVoice] = useState("en-US-Standard-A")
  const [mp3Loading, setMp3Loading] = useState(false)
  const [mp3, setMp3] = useState(null)

  const [initialImage, setInitialImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [croppedImageBase64, setCroppedImageBase64] = useState(null)
  const [imageBase64, setImageBase64] = useState(null)
  const [filename, setFilename] = useState(null)
  const [cropper, setCropper] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    rpc.post(
      "",
      buildRequest(
        "admin.vocabulary.words.get",
        {
          word_id: wordId,
        }
      )
    ).then((response) => {
      if (!response.data.result.message) {
        setWord(response.data.result.word)
        setTranslation(response.data.result.translation)
        setInitialImage(response.data.result.image)
        setAudioUrl(response.data.result.audio)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        setErrorMessage(response.data.result.message)
      }
    }).catch((error) => {
      setErrorMessage(error.message)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleVoiceChange = (event) => {
    setVoice(event.target.value)
  };

  const getImageUrl = (e) => {
    if (e.target.files) {
      // Encode the file using the FileReader API
      const reader = new FileReader()
      reader.onloadend = () => {
        setImageBase64(reader.result)
      }
      reader.readAsDataURL(e.target.files[0])
      setFilename(`${uuid()}.${e.target.files[0].name.split('.').pop()}`)
      setImageUrl(URL.createObjectURL(e.target.files[0]))
    }
  }

  const getCropData = async () => {
    if (cropper) {
      setCroppedImageBase64(cropper.getCroppedCanvas().toDataURL())
    }
  }

  const submitForm = (e) => {
    e.preventDefault()

    if ([
      word.length > 0,
      translation.length > 0,
    ].some(x => x === false)) {
      return false
    }

    let image = null
    let newAudio = null

    if (croppedImageBase64) {
      image = {
        base64: croppedImageBase64,
        name: filename,
      }
    } else if (imageUrl) {
      image = {
        base64: imageBase64,
        name: filename,
      }
    }

    if (mp3) {
      newAudio = `data:audio/mp3;base64,${mp3}`
    }

    rpc.post(
      "",
      buildRequest(
        "admin.vocabulary.words.update",
        {
          word_id: wordId,
          word,
          translation,
          image,
          audio: newAudio,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setWord(response.data.result.word)
        setTranslation(response.data.result.translation)
        setInitialImage(response.data.result.image)
        setAudioUrl(response.data.result.audio)
        setImageUrl(null)
        setImageBase64(null)
        setCroppedImageBase64(null)
        setFilename(null)
        setCropper(null)
        setMp3(null)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        setErrorMessage(response.data.result.message)
      }
    })
    return false
  }

  const generateSound = (e) => {
    e.preventDefault()
    setMp3Loading(true)
    rpc.post(
      "",
      buildRequest(
        "admin.vocabulary.synt",
        {
          text: word,
          voice,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        if (response.data.result.mp3_base64) {
          setMp3(response.data.result.mp3_base64)
          setMp3Loading(false)
        } else {
          setErrorMessage("missing mp3_base64 in response")
        }
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        setErrorMessage(response.data.result)
      }
    })
    return false
  }

  return (
    <>
      <Helmet>
        <title>Word {word ? `${word}` : 'loading...'}</title>
      </Helmet>
      <Fab
        color="primary"
        aria-label="save"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        disabled={!word || [
          word.length > 0,
          translation.length > 0,
        ].some(x => x === false)}
        onClick={submitForm}
      >
        <SaveIcon />
      </Fab>

      <Container fixed>
        <form onSubmit={submitForm} method="post">
          <Box sx={{ flexGrow: 1, marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant="h4" component="h1" gutterBottom>
                    Word {word ? `${word}` : 'loading...'}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Paper>
                      {word && (
                        <Stack padding={1} spacing={1}>
                          <TextField
                            label="Word"
                            error={word.length < 1}
                            helperText={word.length > 0 ? "" : "Word is required"}
                            value={word}
                            onChange={(e) => setWord(e.target.value)}
                          />

                          <TextField
                            label="Translation"
                            error={translation.length < 1}
                            helperText={translation.length > 0 ? "" : "Translation is required"}
                            value={translation}
                            onChange={(e) => setTranslation(e.target.value)}
                          />
                        </Stack>
                      )}
                    </Paper>
                  </Grid>
                  {errorMessage &&
                    <Grid item xs={12}>
                      <Paper>
                        <Alert severity="error">{errorMessage}</Alert>
                      </Paper>
                    </Grid>
                  }
                  {audioUrl && (
                    <Grid item xs={12}>
                      <Paper sx={{ padding: 1 }}>
                        <Typography variant="overline">
                          Sound
                        </Typography>
                        <Stack spacing={1} direction={'row'}>
                          <Stack spacing={1}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={voice}
                              label="Voice"
                              onChange={handleVoiceChange}
                            >
                              <MenuItem value={"en-US-Standard-A"}>MALE_A</MenuItem>
                              <MenuItem value={"en-US-Standard-B"}>MALE_B</MenuItem>
                              <MenuItem value={"en-US-Standard-C"}>FEMALE_C</MenuItem>
                              <MenuItem value={"en-US-Standard-D"}>MALE_D</MenuItem>
                              <MenuItem value={"en-US-Standard-E"}>FEMALE_E</MenuItem>
                              <MenuItem value={"en-US-Standard-F"}>FEMALE_F</MenuItem>
                              <MenuItem value={"en-US-Standard-G"}>FEMALE_G</MenuItem>
                              <MenuItem value={"en-US-Standard-H"}>FEMALE_H</MenuItem>
                              <MenuItem value={"en-US-Standard-I"}>MALE_I</MenuItem>
                              <MenuItem value={"en-US-Standard-J"}>MALE_J</MenuItem>
                            </Select>

                            <Button
                              disabled={mp3Loading}
                              variant="contained" onClick={generateSound}
                            >
                              {mp3Loading ? <CircularProgress /> : 'Synthetize new sound'}
                            </Button>
                          </Stack>
                          <Stack spacing={1}>
                            {(mp3) && (
                              <>
                                <Typography
                                  variant="overline"
                                  sx={{ color: 'red' }}
                                >
                                  Generated sound
                                </Typography>
                                <audio
                                  controls
                                  src={`data:audio/mp3;base64,${mp3}`}
                                />
                              </>
                            )}
                            {(audioUrl && !mp3) && (
                              <>
                                <Typography variant="overline"
                                  sx={{ color: 'green' }}>
                                  Current sound
                                </Typography>
                                <audio
                                  controls
                                  src={audioUrl}
                                />
                              </>
                            )}
                            <Button variant="link" onClick={() => setMp3(null)}>
                              Remove sound
                            </Button>
                          </Stack>
                        </Stack>
                      </Paper>
                    </Grid>
                  )}
                  {(imageUrl && !croppedImageBase64) && (
                    <Grid item xs={12}>
                      <Paper>
                        <Stack padding={1} spacing={1}>
                          <Cropper
                            src={imageUrl}
                            style={{ height: 400, width: 400 }}
                            aspectRatio={262 / 148}
                            minCropBoxWidth={262}
                            minCropBoxHeight={148}
                            guides={false}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                              setCropper(instance);
                            }}
                          />
                          <Button variant="contained" onClick={getCropData}>
                            <TransformIcon /> Crop Image
                          </Button>
                        </Stack>
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <Stack padding={1} spacing={1}>
                    <Button
                      variant="contained"
                      component="label"
                    >
                      Upload image
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
                        hidden
                        onChange={getImageUrl}
                      />
                    </Button>

                    {imageUrl ? (
                      <>
                        <img src={croppedImageBase64 || imageUrl} alt={word} />
                        <Button
                          variant="contained"
                          disabled={!croppedImageBase64}
                          onClick={() => setCroppedImageBase64(null)}
                        >
                          <UndoIcon /> Recrop Image
                        </Button>
                      </>
                    ) : (
                      <img src={initialImage} alt={word} />
                    )}

                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone';
import { v4 as uuid } from 'uuid';

import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import MuiAlert from '@mui/material/Alert';

import Editor from 'components/elements/Editor'
import AssignmentImage from 'components/elements/AssignmentImage'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function TheoryAssignmentForm(props) {

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg'],
    },
    onDrop: acceptedFiles => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          setFiles(
            acceptedFiles.map((file) => {
              const filename = `${uuid()}.${file.name.split('.').pop()}`
              return Object.assign(file, {
                filename: filename,
                preview: URL.createObjectURL(file),
                base64Image: e.target.result,
                link: `https://cdn.icepig.online/media/assignments/${filename}`,
              })
            })
          )
        }
        reader.readAsDataURL(file)
      })
    }
  });

  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [name, setName] = useState('')
  const [theory, setTheory] = useState('')

  useEffect(() => {
    if (theory === null) {
      setTheory('')
    }
  }, [theory])


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const removeFile = (index) => {
    URL.revokeObjectURL(files[index].preview)
    setFiles(files.filter((file, i) => i !== index))
  }

  const thumbs = files.map((file, index) => (
    <AssignmentImage
      preview={file.preview}
      link={file.link}
      index={index}
      removeFile={removeFile}
    />
  ));

  const submitForm = (e) => {
    e.preventDefault()
    if (name.length < 1 || theory.length < 1) { return false }

    const yamlContent = `version: 2
type: theory
name: ${name}
level: A0
content: |

${theory.split('\n').map(line => `  ${line}`).join('\n')}

`
    rpc.post(
      "",
      buildRequest(
        "admin.assignments.create_theory_attach",
        {
          name,
          lesson_id: props.lessonId,
          source_yaml: yamlContent,
          files: files.map(file => ({
            name: file.filename,
            base64: file.base64Image
          })),
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        if (response.data.result.success) {
          setMessage(`Theory [${name}] has been created and attached to the Lesson [${props.lessonId}]`)
          setName('')
          setTheory('')
          files.forEach((file, i) => removeFile(i))
          props.refreshLesson(props.lessonId)
          setOpen(true)
        } else {
          setMessage("Something went wrong")
          setOpen(true)
        }
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        setMessage(response.data.result.message)
        console.error(response.data.result)
        setOpen(true)
      }
    })
    return false
  }

  if (props.lessonId) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="overline" gutterBottom>
              Create Theory and attach it to the Lesson
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <form onSubmit={submitForm} method="post">
              <Stack direction="column" spacing={2}>
                <TextField
                  type="text"
                  id="theory-name"
                  label="Name"
                  variant="standard"
                  value={name ? name : ''}
                  onChange={(e) => { setName(e.target.value) }}
                  required
                />
                <Typography variant="body1" gutterBottom>
                  version: 2<br />
                  type: theory<br />
                  name: name<br />
                  level': A0<br />
                  content: |<br /><br />
                </Typography>
                {theory !== null && (
                  <Editor
                    defaultValue={theory || ''}
                    value={theory}
                    setValue={setTheory}
                  />
                )}

                <div>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                  <ImageList>
                    {thumbs}
                  </ImageList>
                </div>

                <Button
                  type="submit"
                  variant="contained"
                  onClick={submitForm}
                  disabled={!name || !theory}
                >
                  Create and attach
                </Button>
              </Stack>
            </form>
          </Grid>
        </Grid>

        <Snackbar
          open={open}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          autoHideDuration={2000}
          onClose={handleClose}
          action={action}
        >
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Box>
    )
  } else {
    return (
      <Stack spacing={1}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="text" sx={{ fontSize: '1.1rem' }} />
        {/* For other variants, adjust the size with `width` and `height` */}
        <Stack direction="row" spacing={2}>
          <Skeleton variant="rectangular" width={200} height={40} />
          <Skeleton variant="rectangular" width={200} height={40} />
        </Stack>
      </Stack>
    )
  }
}

import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

// Importing it here to prevent duplicate import
import "cropperjs/dist/cropper.css"

import { HelmetProvider } from 'react-helmet-async'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"

import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from "material-ui-confirm";

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import HomePage from 'pages/Home'
import LoginPage from 'pages/LoginPage'
import GroupsPage from 'pages/GroupsPage'
import GroupDetailPage from 'pages/GroupsPage/GroupDetailPage'
import GroupsCreatePage from 'pages/GroupsPage/GroupsCreate'
import LessonDetailsPage from 'pages/LessonDetailsPage'
import AssignmentResultDetailsPage from 'pages/AssignmentResultDetailsPage'
import AssignmentsPage from 'pages/Assignments'
import AssignmentDetailsPage from 'pages/Assignments/AssignmentDetails'
import GeneratorsPage from 'pages/Generators'
import GeneratorVoicePage from 'pages/Generators/GeneratorVoice'
import GeneratorWordsPage from 'pages/Generators/GeneratorWords'
import AssignmentCreatePage from 'pages/Assignments/AssignmentCreate'
import WordsPage from 'pages/Words'
import WordCreateSinglePage from 'pages/Words/WordCreateSingle'
import WordDetailsPage from 'pages/Words/WordDetails'
import WordCreateBulkPage from 'pages/Words/WordCreateBulk'
import UsersPage from 'pages/Users'
import UserDetailsPage from 'pages/Users/UserDetails'
import UserCreatePage from 'pages/Users/UsersCreate'
import VideoUploadPage from 'pages/Videos';
import VideoLibraryPage from 'pages/Videos/VideoList';
import VideoDetailPage from 'pages/Videos/VideoDetail';

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

import Header, { DrawerHeader } from '../Header'

export const UserContext = React.createContext()
export const ThemeContext = React.createContext()

class App extends React.PureComponent {

  changeTheme = (className) => {
    this.setState({ theme: { ...this.state.theme, className } })
  }

  state = {
    currentUser: null,
    accessKey: localStorage.getItem("accessKey"),
    theme: {
      className: "bg-white",
      changeTheme: this.changeTheme
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ((window.location.pathname !== "/login") && (prevState.currentUser === null)) {
      rpc.post("",
        buildRequest(
          "admin.profile.get",
          null
        )
      )
        .then(response => {
          if (response.data.result.code === 5 || response.data.result.code === 6) {
            signoutAndRedirectToIndex()
          } else {
            this.setState({ currentUser: response.data.result })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeContext.Provider value={this.state.theme}>
          <UserContext.Provider value={this.state.currentUser}>
            <ConfirmProvider>
              <SnackbarProvider>
                <HelmetProvider>
                  <Router>
                    <Box sx={{ display: 'flex' }}>
                      <CssBaseline />

                      <Header />

                      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <DrawerHeader />
                        <Routes>
                          <Route path="/" element={this.state.accessKey ? <HomePage /> : <Navigate to="/login" />} />
                          <Route path="/login" element={<LoginPage />} />
                          <Route path="/generators/*" element={this.state.accessKey ? <Generators /> : <Navigate to="/login" />} />
                          <Route path="/groups/*" element={this.state.accessKey ? <Groups /> : <Navigate to="/login" />} />
                          <Route path="/assignments/*" element={this.state.accessKey ? <Assignments /> : <Navigate to="/login" />} />
                          <Route path="/results/:resultId" element={this.state.accessKey ? <AssignmentResultDetailsPage /> : <Navigate to="/login" />} />
                          <Route path="/words/*" element={this.state.accessKey ? <Words /> : <Navigate to="/login" />} />
                          <Route path="/users/*" element={this.state.accessKey ? <Users /> : <Navigate to="/login" />} />
                          <Route path="/videos/*" element={this.state.accessKey ? <Videos /> : <Navigate to="/login" />} />
                        </Routes>
                      </Box>
                    </Box>
                  </Router>
                </HelmetProvider>
              </SnackbarProvider>
            </ConfirmProvider>
          </UserContext.Provider>
        </ThemeContext.Provider>
      </LocalizationProvider>
    )
  }
}

function Generators() {
  return (
    <Routes>
      <Route path="/" element={<GeneratorsPage />} />
      <Route path="/voice" element={<GeneratorVoicePage />} />
      <Route path="/words" element={<GeneratorWordsPage />} />
    </Routes>
  )
}

function Groups() {
  return (
    <Routes>
      <Route path="/" element={<GroupsPage />} />
      <Route path="/create" element={<GroupsCreatePage />} />
      <Route path="/:page" element={<GroupsPage />} />
      <Route path="/details/:groupId" element={<GroupDetailPage />} />
      <Route path="/details/:groupId/:page" element={<GroupDetailPage />} />
      <Route path="/details/:groupId/lesson/:lessonId" element={<LessonDetailsPage />} />
    </Routes>
  )
}

function Assignments() {
  return (
    <Routes>
      <Route path="/" element={<AssignmentsPage />} />
      <Route path="/create" element={<AssignmentCreatePage />} />
      <Route path="/:page" element={<AssignmentsPage />} />
      <Route path="/details/:assignmentId" element={<AssignmentDetailsPage />} />
      <Route path="/details/:assignmentId/results/:resultId" element={<AssignmentResultDetailsPage />} />
    </Routes>
  )
}

function Words() {
  return (
    <Routes>
      <Route path="/" element={<WordsPage />} />
      <Route path="/create/single" element={<WordCreateSinglePage />} />
      <Route path="/create/bulk" element={<WordCreateBulkPage />} />
      <Route path="/:page" element={<WordsPage />} />
      <Route path="/details/:wordId" element={<WordDetailsPage />} />
    </Routes>
  )
}

function Users() {
  return (
    <Routes>
      <Route path="/" element={<UsersPage />} />
      <Route path="/:page" element={<UsersPage />} />
      <Route path="/create" element={<UserCreatePage />} />
      <Route path="/details/:id" element={<UserDetailsPage />} />
    </Routes>
  )
}

function Videos() {
  return (
    <Routes>
      <Route path="/" element={<VideoUploadPage />} />
      <Route path="/library" element={<VideoLibraryPage />} />
      <Route path="/details/:videoId" element={<VideoDetailPage />} />
    </Routes>
  )
}

export default App

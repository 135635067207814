import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink } from 'react-router-dom'

import Typography from '@mui/material/Typography';
import ContentCopy from '@mui/icons-material/ContentCopy';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';

// import Pagination from 'components/elements/Pagination';

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'
import { CDN_URL } from 'config';

export default function VideoLibraryPage() {
  const theme = useContext(ThemeContext)

  // let { page = 1 } = useParams()

  const [videos, setVideos] = useState()
  // const [perPage, setPerPage] = useState(15)
  // const [total, setTotal] = useState(0)

  // const [showAll, setShowAll] = useState(localStorage.getItem("groups_showAll") === "true")

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // when `showAll` changes we need to re-request groups list
    // localStorage.setItem("groups_showAll", showAll)
    setVideos(null)
    rpc.post(
      "",
      buildRequest(
        "admin.videos.list",
        {},
      )
    ).then(response => {
      if (!response.data.result.message) {
        setVideos(response.data.result)
        // setTotal(response.data.result.records)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })

  }, [])

  const renderStatus = (status) => {
    // ('uploaded', 'Uploaded'),
    // ('conversion', 'Conversion'),
    // ('ready', 'Ready'),
    // ('error', 'Error'),
    // ('published', 'Published'),
    switch (status) {
      case "uploaded":
        return <Chip label="Uploaded" color="primary" variant="outlined" />
      case "conversion":
        return <Chip label="Conversion" color="primary" variant="filled" />
      case "ready":
        return <Chip label="Ready" color="success" variant="outlined" />
      case "error":
        return <Chip label="Error" color="error" variant="filled" />
      case "published":
        return <Chip label="Published" color="success" variant="filled" />
      default:
        return <Chip label={status} />
    }
  }

  return (
    <>
      <Helmet>
        <title>Video Library</title>
      </Helmet>

      <Container fixed sx={{ paddingBottom: 7 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3" gutterBottom>
              Videos
            </Typography>
          </Stack>

          <Paper>
            {!videos &&
              <CircularProgress />
            }
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Title</TableCell>
                    <TableCell align="left">Last updated</TableCell>
                    <TableCell align="left">Created updated</TableCell>
                    <TableCell align="left">Preview</TableCell>
                    <TableCell aligh="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {videos && videos.map(video =>
                    <TableRow key={`video-${video.id}`}>
                      <TableCell>
                        <Link to={`/videos/details/${video.id}`} component={RouterLink}>
                          {video.id}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {renderStatus(video.status)}
                      </TableCell>
                      <TableCell>
                        <Link to={`/videos/details/${video.id}`} component={RouterLink}>
                          {video.title}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {moment(video.updated_at).format('DD-MM-YYYY HH:mm')}
                      </TableCell>
                      <TableCell>
                        {moment(video.created_at).format('DD-MM-YYYY HH:mm')}
                      </TableCell>
                      <TableCell>
                        <img src={`${CDN_URL}/videos/${video.job_id}/${video.thumbnail}`} alt="preview" style={{ width: 100 }} />
                      </TableCell>
                      <TableCell align="right">
                        <Button variant="outlined" onClick={() => { navigator.clipboard.writeText(`<video src="${CDN_URL}/videos/${video.dash_manifest}" />`) }}>
                          <ContentCopy />
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
    </>
  )
}

import React, { useState, useContext, useEffect } from 'react'
import { enqueueSnackbar } from 'notistack'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import SaveIcon from '@mui/icons-material/Save'


import { ThemeContext } from 'components/common/App'

import { CDN_URL } from 'config'
import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

export default function VideoDetailPage() {
  const theme = useContext(ThemeContext)

  let { videoId = 1 } = useParams()

  const [video, setVideo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fabColor, setFabColor] = useState("primary")
  const [errorMessage, setErrorMessage] = useState(null)

  // const [imageUrl, setImageUrl] = useState(null)
  // const [croppedImageBase64, setCroppedImageBase64] = useState(null)
  // const [cropper, setCropper] = useState(null)

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    rpc.post("", buildRequest("admin.videos.get", { maybe_video_id: videoId }))
      .then(({ data, errors }) => {
        if (data.result) {
          setVideo(data.result)
        } else {
          if (data.error.code === 5) {
            signoutAndRedirectToIndex()
          }
        }
      })
  }, [videoId])

  const saveVideo = () => {
    setLoading(true)
    setFabColor("secondary")

    rpc.post("", buildRequest("manager.videos.update", { ...video }))
      .then(({ data, errors }) => {
        if (data.result) {
          enqueueSnackbar("Video saved", { variant: 'success' })
        } else {
          setErrorMessage(data.error.message)
          enqueueSnackbar(data.error.message, { variant: 'error' })
        }
      })
      .finally(() => {
        setLoading(false)
        setFabColor("primary")
      })
  }

  // const getImageUrl = (e) => {
  //   if (e.target.files) {
  //     // Encode the file using the FileReader API
  //     const reader = new FileReader()
  //     reader.onloadend = () => {
  //       setImageBase64(reader.result)
  //     }
  //     reader.readAsDataURL(e.target.files[0])
  //     setFilename(`${uuid()}.${e.target.files[0].name.split('.').pop()}`)
  //     setImageUrl(URL.createObjectURL(e.target.files[0]))
  //   }
  // }

  // const getCropData = async () => {
  //   if (cropper) {
  //     setCroppedImageBase64(cropper.getCroppedCanvas().toDataURL())
  //   }
  // }
  return (
    <>
      <Helmet>
        <title>Video {video ? `${video.status}: ${video.title}` : '...'}</title>
      </Helmet>

      <Fab
        color={fabColor}
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={saveVideo}
      >
        {loading ? <CircularProgress color="inherit" /> : <SaveIcon />}
      </Fab>

      <Container fixed>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={'space-between'}
                flexWrap={'wrap'}
              >
                <Typography variant="h4" gutterBottom>
                  {video ? `${video.status}: ${video.title}` : '...'}
                </Typography>
              </Stack>
            </Grid>
            {errorMessage && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} md={8}>
              <Paper>
                <Stack spacing={2} sx={{ p: 2 }}>
                  <TextField
                    required
                    id="title"
                    label="Title"
                    value={(video && video.title) || ''}
                    onChange={(e) => setVideo({ ...video, title: e.target.value })}
                  // disabled={!user}
                  />

                  <TextField
                    required
                    id="description"
                    label="Description"
                    value={(video && video.description) || ''}
                    onChange={(e) => setVideo({ ...video, description: e.target.value })}
                    disabled={!video}
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={video && video.status === "published"}
                        onChange={(e) => setVideo({ ...video, status: e.target.checked ? "published" : "ready" })}
                      />
                    }
                    label={(video && video.status === "published") ? "Published" : "Not published"}
                  />

                </Stack>
              </Paper>

              <Paper>
                <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                  {video && video.suggested_thumbnails.map((item) => (
                    <ImageListItem key={`thumbnail-${item.id}`} onClick={() => {
                      setVideo({
                        ...video,
                        thumbnail: `${CDN_URL}/videos/${video.job_id}/${item.thumbnail_url}`
                      })
                    }}>
                      <img
                        src={`${CDN_URL}/videos/${video.job_id}/${item.thumbnail_url}`}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper>
                <Stack spacing={2} sx={{ p: 2 }}>

                  <img
                    src={(video && video.thumbnail) || "https://cdn.icepig.online/static/images/avatars/default.png"}
                    alt="Video Preview"
                    style={{
                      width: 150,
                      height: 84,
                      borderRadius: '5%',
                      backgroundColor: '#ccc',
                      margin: '0 auto',
                    }}
                  />
                  {/*}
                  <Button
                    variant="contained"
                    component="label"
                  >
                    Upload image
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
                      hidden
                      onChange={getImageUrl}
                    />
                  </Button>
                */}
                </Stack>
              </Paper>
            </Grid>
            {/*
            {(imageUrl && !croppedImageBase64) && (
              <Grid item xs={12}>
                <Paper>
                  <Stack padding={1} spacing={1}>
                    <Cropper
                      src={imageUrl}
                      style={{ height: 600, width: 338 }}
                      aspectRatio={16 / 9}
                      minCropBoxWidth={1920}
                      minCropBoxHeight={1080}
                      guides={false}
                      checkOrientation={false}
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                    />
                    <Button variant="contained" onClick={getCropData}>
                      <TransformIcon /> Crop Image
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
            )}
            */}
          </Grid>
        </Box>
      </Container >
    </>
  )
}

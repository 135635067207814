import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import "cropperjs/dist/cropper.css"

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import Editor from 'components/elements/Editor'
import WordRow from './word_row'

import { ThemeContext } from 'components/common/App'

export default function WordCreateBulkPage() {
  const theme = useContext(ThemeContext)

  const [rawWords, setRawWords] = useState("")

  const [words, setWords] = useState(null)

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createWords = () => {
    const extractedWords = rawWords.split('\n').map((word) => {
      const [extractedWord, extractedTranslation] = word.split(' - ')
      return { word: extractedWord, translation: extractedTranslation }
    })
    setWords(extractedWords)
  }

  return (
    <>
      <Helmet>
        <title>Create Words Bulk</title>
      </Helmet>

      <Container fixed>
        <Box sx={{ flexGrow: 1, marginTop: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant="h4" component="h1" gutterBottom>
                  Create Words Bulk
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Paper padding={1}>
                <Stack spacing={2}>
                  <Editor
                    height={'25vh'}
                    value={rawWords}
                    setValue={setRawWords}
                  />
                  <Button
                    disabled={rawWords.length < 1}
                    variant="contained"
                    onClick={createWords}
                  >
                    Create Words
                  </Button>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              {words && words.map((word) =>
                <WordRow
                  key={word.word}
                  word={word.word}
                  translation={word.translation}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Container >
    </>
  )
}

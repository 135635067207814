import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import Link from '@mui/material/Link'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

export default function LessonLink(props) {

  return (
    <Item elevation={props.elevation || 0}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="flex-start"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Typography variant="primary">
          {props.number}
        </Typography>
        <Typography variant="primary">
          [{props.id}]
        </Typography>
        <Typography variant="primary">
          <Link to={`/groups/details/${props.group.id}/lesson/${props.id}`} component={RouterLink}>{props.group.name}</Link>
        </Typography>
      </Stack>
    </Item>
  )
}

import { useState, useEffect } from 'react'
import { enqueueSnackbar } from 'notistack'
import rpc, { buildRequest } from 'lib/rpc'

import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import AddIcon from '@mui/icons-material/Add';

import Student from 'components/users/Student'

export default function GroupStudents(props) {
  const [grouplessStudents, setGrouplessStudents] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    refresh()
  }, [props.refresher])

  const refresh = () => {
    setLoading(true)
    rpc.post(
      "",
      buildRequest(
        "admin.users.groupless_students",
        null,
      ),
    ).then(({ data, errors }) => {
      setLoading(false)
      if (data.result) {
        setGrouplessStudents(data.result)
      }
    })
  }

  const addStudentToGroup = (student) => {
    rpc.post("", buildRequest("admin.groups.add_student", {
      group_id: props.groupId,
      student_id: student.id,
    })).then(({ data, errors }) => {
      if (data.result) {
        enqueueSnackbar(`${student.first_name} ${student.last_name} added to group`, { variant: 'success' })
        setGrouplessStudents(grouplessStudents.filter(s => s.id !== student.id))
        if (props.onAddStudent) {
          props.onAddStudent(student)
        }
      }
    })
  }

  return (
    <Stack spacing={2} flexGrow={1}>
      <Typography variant="h6">Students without a group</Typography>
      <Divider />

      {loading && <CircularProgress />}
      {!loading && grouplessStudents.length === 0 && <Typography>No students without a group</Typography>}
      {grouplessStudents.map(student =>
        <Stack
          key={`student-${student.id}`}
          direction={'row'}
          spacing={2}
          flexGrow={1}
          alignItems={'center'}
          justifyContent={'space-between'}>
          <Student {...student} />
          {props.groupId && (
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => addStudentToGroup(student)}
            >Add to group</Button>
          )}
        </Stack>
      )}
    </Stack>
  )
}
import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import AbcIcon from '@mui/icons-material/Abc'

import GeneratorLink from 'components/elements/GeneratorLink'

import { ThemeContext } from 'components/common/App'

export default function GeneratorsPage() {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>Generators</title>
      </Helmet>
      <Container fixed>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} spacing={2}>
              <Typography variant="h3" gutterBottom>
                Generators
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <GeneratorLink
                to="/generators/voice"
                title="Voice"
                description="Генерує голосові файли з тексту"
              >
                <RecordVoiceOverIcon />
              </GeneratorLink>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <GeneratorLink
                to="/generators/words"
                title="Words"
                description="Генерує завдання зі словами (теорія, інпут і радіо)"
              >
                <AbcIcon />
              </GeneratorLink>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

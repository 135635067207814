import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import { ThemeContext } from 'components/common/App';

import videoApi from 'lib/video-api';

// Styles for the hidden input file
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


export default function VideoUploadPage() {
  const theme = useContext(ThemeContext)
  const navigate = useNavigate()

  const [videoAPIHealth, setVideoAPIHealth] = useState(false)
  const [videoFile, setVideoFile] = useState(null);
  const [loading, setLoading] = useState(false)
  const [filename, setFilename] = useState("No file selected")

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps

    videoApi.get("/v1/health").then(response => {
      if (response.data.status === "ok") {
        setVideoAPIHealth(true)
      } else {
        setVideoAPIHealth(false)
      }
    }
    ).catch(error => {
      console.error(error)
      setVideoAPIHealth(false)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFileChange = (event) => {
    setVideoFile(event.target.files[0])
    setFilename(event.target.files[0].name)
  }

  const submitForm = (e) => {
    e.preventDefault()
    setLoading(true);
    const formData = new FormData();
    formData.append('file', videoFile); // 'file' should match the key expected by your API

    videoApi.post('/v1/video/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Required for file uploads
        'X-Token': localStorage.getItem('accessKey'), // We need to send the access key to the API for authentication, the API should have our token in the ALLOWED_TOKENS section
      },
    })
      .then(response => {
        setLoading(false);
        navigate(`/videos/details/${response.data.job_id}`)
        setVideoFile(null); // clear the file to prevent re-uploading
      })
      .catch(error => {
        setLoading(false);
        console.error('Error uploading file:', error);
      });
  }

  return (
    <>
      <Helmet>
        <title>Video Upload</title>
      </Helmet>
      <Container fixed>
        <form onSubmit={submitForm} method="post">
          <Box sx={{ flexGrow: 1, marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={2}>
                  <Typography variant="h3">
                    Video Upload
                  </Typography>
                  <Tooltip title="Connection Status for Video Library Manager">
                    {videoAPIHealth ?
                      <CloudQueueIcon fontSize='large' color="success" />
                      :
                      <CloudOffIcon fontSize='large' color="error" />
                    }
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack direction={'row'} spacing={2} alignItems={'flex-start'}>
                  <Button
                    disabled={loading || !videoAPIHealth}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Select Video
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                  <Button
                    type="submit"
                    disabled={videoFile === null || loading || !videoAPIHealth}
                    variant="contained"
                    onClick={submitForm}
                  >
                    {loading ? <CircularProgress /> : 'Upload'}
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">
                  {filename}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>
    </>
  )
}

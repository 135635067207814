import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom'

import CircularProgress from '@mui/material/CircularProgress'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import ModeEditIcon from '@mui/icons-material/ModeEdit'
import AddIcon from '@mui/icons-material/Add'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'

import Pagination from 'components/elements/Pagination'
import Search from 'components/forms/Search'

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

export default function WordsPage() {
  const theme = useContext(ThemeContext)

  let { page = 1 } = useParams()
  const navigate = useNavigate()

  const [words, setWords] = useState(null)
  const [perPage, setPerPage] = useState(10)
  const [total, setTotal] = useState(0)
  const [searchString, setSearchString] = useState("")

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setWords(null)

    rpc.post(
      "",
      buildRequest(
        "admin.vocabulary.words.list",
        {
          page: page,
          per_page: perPage,
          search: searchString,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setWords(response.data.result.data)
        setTotal(response.data.result.records)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })
  }, [page, perPage, searchString])

  const setPage = (page) => {
    navigate(`/words/${page}`)
  }

  return (
    <>
      <Helmet>
        <title>Words</title>
      </Helmet>

      <SpeedDial
        ariaLabel="SpeedDial example"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
        FabProps={{ color: 'primary' }}
        direction="up"
      >
        <SpeedDialAction
          key="add"
          icon={<AddIcon />}
          tooltipTitle="Add single"
          onClick={() => navigate("/words/create/single")}
        />
        <SpeedDialAction
          key="add-bulk"
          icon={<PlaylistAddIcon />}
          tooltipTitle="Add bulk"
          onClick={() => navigate("/words/create/bulk")}
        />
      </SpeedDial>

      <Container fixed>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h3" gutterBottom>
                Words
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper padding={5}>
                <Search value={searchString} setSearchString={setSearchString} />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <TableContainer>
                  <Table size="small" stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="left">Word</TableCell>
                        <TableCell align="left">Translation</TableCell>
                        <TableCell align="left">Sound</TableCell>
                        <TableCell align="left">Image</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {words ?
                        words.map((word) => (
                          <TableRow
                            key={word.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              <Link to={`/words/details/${word.id}`} component={RouterLink}>{word.id}</Link>
                            </TableCell>
                            <TableCell align="left">
                              <Link to={`/words/details/${word.id}`} component={RouterLink}><ModeEditIcon /> {word.word}</Link>
                            </TableCell>
                            <TableCell align="left">{word.translation}</TableCell>
                            <TableCell align="left">
                              {word.audio && (
                                <audio controls={true} src={word.audio} />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              <img src={word.image} alt={word.word} width="100" />
                            </TableCell>
                          </TableRow>
                        ))
                        :
                        <TableRow>
                          <TableCell colSpan={5}>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      }
                    </TableBody>
                    <Pagination
                      total={total}
                      page={page}
                      perPage={perPage}
                      setPage={setPage}
                      setPerPage={setPerPage}
                    />
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container >
    </>
  )
}

import React, { useState, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Paper,
  Typography,
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material'

import { ContentCopy, HelpOutline } from '@mui/icons-material'

import Editor from 'components/elements/Editor'

import { ThemeContext } from 'components/common/App'

import { wordTheoryGenerator } from 'lib/generators/word-theory'
import { wordRadioGenerator } from 'lib/generators/word-radio'
import { wordInputGenerator } from 'lib/generators/word-input'
import { wordWordcardsGenerator } from 'lib/generators/word-wordcards'

export default function GeneratorWordsPage() {
  const theme = useContext(ThemeContext)

  const [showHelp, setShowHelp] = useState(localStorage.getItem("gWords_help") === null ? true : localStorage.getItem("gWords_help") === "true")

  const [text, setText] = useState()
  const [topic, setTopic] = useState("")
  const [limit, setLimit] = useState(0)
  const [level, setLevel] = useState("A1")
  const [includeSimpleWords, setIncludeSimpleWords] = useState(true)
  const [includeWordTags, setIncludeWordTags] = useState(true)
  const [genTheory, setGenTheory] = useState(true)
  const [genRadioEnUa, setGenRadioEnUa] = useState(true)
  const [genRadioUaEn, setGenRadioUaEn] = useState(true)
  const [genInputUaEn, setGenInputUaEn] = useState(true)
  const [genWordCards, setGenWordCards] = useState(true)
  const [wordCardsShuffle, setWordCardsShuffle] = useState(true)

  const [theoryGenerated, setTheoryGenerated] = useState(null)
  const [radioEnUaGenerated, setRadioEnUaGenerated] = useState(null)
  const [radioUaEnGenerated, setRadioUaEnGenerated] = useState(null)
  const [inputUaEnGenerated, setInputUaEnGenerated] = useState(null)
  const [wordCardsGenerated, setWordCardsGenerated] = useState(null)

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (showHelp) {
      localStorage.setItem("gWords_help", true)
    } else {
      localStorage.setItem("gWords_help", false)
    }
  }, [showHelp])

  const submitForm = (e) => {
    e.preventDefault()

    if (genTheory) {
      setTheoryGenerated(wordTheoryGenerator(text, topic, level, includeSimpleWords, includeWordTags))
    } else {
      setTheoryGenerated(null)
    }

    if (genRadioEnUa) {
      setRadioEnUaGenerated(wordRadioGenerator(text, topic, level, limit))
    } else {
      setRadioEnUaGenerated(null)
    }

    if (genRadioUaEn) {
      setRadioUaEnGenerated(wordRadioGenerator(text, topic, level, limit, true))
    } else {
      setRadioUaEnGenerated(null)
    }

    if (genInputUaEn) {
      setInputUaEnGenerated(wordInputGenerator(text, topic, level, limit))
    } else {
      setInputUaEnGenerated(null)
    }

    if (genWordCards) {
      setWordCardsGenerated(wordWordcardsGenerator(text, topic, level, limit))
    } else {
      setWordCardsGenerated(null)
    }
  }

  return (
    <>
      <Helmet>
        <title>Generator Words</title>
      </Helmet>
      <Container fixed>
        <form onSubmit={submitForm} method="post">
          <Box sx={{ flexGrow: 1, marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h3">
                  Words Generator <HelpOutline style={{ cursor: 'pointer' }} onClick={(e) => setShowHelp(true)} />
                </Typography>
              </Grid>
              {showHelp && (
                <Grid item xs={12}>
                  <Paper style={{ padding: 5 }}>
                    <Typography variant="body1">
                      Цей генератор приймає слова у вигляді:
                    </Typography>
                    <pre>
                      word - переклад<br />
                      another - інший переклад<br />
                      yet another one - ще один переклад, або не один через кому
                    </pre>
                    <Typography variant="body1">
                      У відповідь генерує завдання:
                      <ul>
                        <li>Теорія зі словами</li>
                        <li>Радіо "обери переклад" з 🇬🇧 на 🇺🇦</li>
                        <li>Радіо "обери переклад" з 🇺🇦 на 🇬🇧</li>
                        <li>Input "впиши переклад" з 🇺🇦 на 🇬🇧</li>
                      </ul>
                    </Typography>
                    <Button onClick={(e) => setShowHelp(false)}>Dismiss help</Button>
                  </Paper>
                </Grid>
              )}
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper>
                      <Editor
                        setValue={setText}
                      />
                    </Paper>
                  </Grid>
                  {theoryGenerated && (
                    <>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent={'space-between'}>
                          <Typography variant="h4">
                            Theory
                          </Typography>
                          <Button variant="outlined" onClick={() => { navigator.clipboard.writeText(theoryGenerated) }}>
                            <ContentCopy />
                          </Button>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper>
                          <Editor
                            defaultValue={theoryGenerated}
                            value={theoryGenerated}
                            setValue={setTheoryGenerated}
                          />
                        </Paper>
                      </Grid>
                    </>
                  )}
                  {radioEnUaGenerated && (
                    <>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent={'space-between'}>
                          <Typography variant="h4">
                            Radio 🇬🇧 &rarr; 🇺🇦
                          </Typography>
                          <Button variant="outlined" onClick={() => { navigator.clipboard.writeText(radioEnUaGenerated) }}>
                            <ContentCopy />
                          </Button>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper>
                          <Editor
                            defaultValue={radioEnUaGenerated}
                            value={radioEnUaGenerated}
                            setValue={setRadioEnUaGenerated}
                          />
                        </Paper>
                      </Grid>
                    </>
                  )}
                  {radioUaEnGenerated && (
                    <>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent={'space-between'}>
                          <Typography variant="h4">
                            Radio 🇺🇦 &rarr; 🇬🇧
                          </Typography>
                          <Button variant="outlined" onClick={() => { navigator.clipboard.writeText(radioUaEnGenerated) }}>
                            <ContentCopy />
                          </Button>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper>
                          <Editor
                            defaultValue={radioUaEnGenerated}
                            value={radioUaEnGenerated}
                            setValue={setRadioUaEnGenerated}
                          />
                        </Paper>
                      </Grid>
                    </>
                  )}
                  {inputUaEnGenerated && (
                    <>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent={'space-between'}>
                          <Typography variant="h4">
                            Input 🇺🇦 &rarr; 🇬🇧
                          </Typography>
                          <Button variant="outlined" onClick={() => { navigator.clipboard.writeText(inputUaEnGenerated) }}>
                            <ContentCopy />
                          </Button>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper>
                          <Editor
                            defaultValue={inputUaEnGenerated}
                            value={inputUaEnGenerated}
                            setValue={setInputUaEnGenerated}
                          />
                        </Paper>
                      </Grid>
                    </>
                  )}
                  {wordCardsGenerated && (
                    <>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent={'space-between'}>
                          <Typography variant="h4">
                            🃏 Wordcards 🃏
                          </Typography>
                          <Button variant="outlined" onClick={() => { navigator.clipboard.writeText(wordCardsGenerated) }}>
                            <ContentCopy />
                          </Button>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Paper>
                          <Editor
                            defaultValue={wordCardsGenerated}
                            value={wordCardsGenerated}
                            setValue={setWordCardsGenerated}
                          />
                        </Paper>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Paper>
                  <Stack spacing={2}>
                    <Stack padding={2} spacing={1}>
                      <Typography variant="overline" component="p">
                        Generator Parameters
                      </Typography>
                      <Select
                        labelId="level-select-label"
                        id="level-select"
                        value={level}
                        label="Level"
                        onChange={(e) => setLevel(e.target.value)}
                      >
                        <MenuItem value={"A0"}>A0</MenuItem>
                        <MenuItem value={"A1"}>A1</MenuItem>
                        <MenuItem value={"A1+"}>A1+</MenuItem>
                        <MenuItem value={"A2"}>A2</MenuItem>
                        <MenuItem value={"B1"}>B1</MenuItem>
                        <MenuItem value={"B2"}>B2</MenuItem>
                        <MenuItem value={"C1"}>C1</MenuItem>
                      </Select>

                      <TextField
                        label="Topic"
                        error={topic.length < 1 ? true : false}
                        helperText={topic.length < 1 ? "Topic is required" : ""}
                        defaultValue={topic}
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                      />

                      <TextField
                        label="Word limit"
                        defaultValue={limit}
                        value={limit}
                        type="number"
                        onChange={(e) => setLimit(e.target.value)}
                      />

                      <Divider />

                      <FormControlLabel control={
                        <Switch
                          checked={genTheory}
                          onChange={(e) => setGenTheory(e.target.checked)}
                        />
                      } label="Теорія" />
                      <FormControlLabel style={{ paddingLeft: 15 }} control={
                        <Switch
                          disabled={!genTheory}
                          checked={includeSimpleWords}
                          onChange={(e) => setIncludeSimpleWords(e.target.checked)}
                        />
                      } label="**слово** - переклад" />
                      <FormControlLabel style={{ paddingLeft: 15 }} control={
                        <Switch
                          disabled={!genTheory}
                          checked={includeWordTags}
                          onChange={(e) => setIncludeWordTags(e.target.checked)}
                        />
                      } label="[[[word]]]" />

                      <FormControlLabel control={
                        <Switch
                          checked={genRadioEnUa}
                          onChange={(e) => setGenRadioEnUa(e.target.checked)}
                        />
                      } label="Радіо 🇬🇧 на 🇺🇦" />
                      <FormControlLabel control={
                        <Switch
                          checked={genRadioUaEn}
                          onChange={(e) => setGenRadioUaEn(e.target.checked)}
                        />
                      } label="Радіо 🇺🇦 на 🇬🇧" />
                      <FormControlLabel control={
                        <Switch
                          checked={genInputUaEn}
                          onChange={(e) => setGenInputUaEn(e.target.checked)}
                        />
                      } label="Інпут 🇺🇦 на 🇬🇧" />

                      <FormControlLabel control={
                        <Switch
                          checked={genWordCards}
                          onChange={(e) => setGenWordCards(e.target.checked)}
                        />
                      } label="✨ Wordcards" />

                      <FormControlLabel style={{ paddingLeft: 15 }} control={
                        <Switch
                          disabled={!genWordCards}
                          checked={wordCardsShuffle}
                          onChange={(e) => setWordCardsShuffle(e.target.checked)}
                        />
                      } label="Suffle 🎲" />

                      <Divider />

                      <Button
                        type="submit"
                        variant="contained"
                        disabled={topic.length < 1 ? true : false}
                        onClick={submitForm}
                      >
                        Generate
                      </Button>
                    </Stack>

                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container >
    </>
  )
}

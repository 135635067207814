import React from 'react'
import Markdown from 'components/common/Markdown'

export default function ScoreDetails(props) {

  const isCorrect = (isCorrectData) => {
    if (typeof(isCorrectData) === 'boolean') {
      return isCorrectData
    } else {
      return isCorrectData.every(elem => elem === true)
    }
  }

  const extractCorrect = (corrects, index) => {
    if (Array.isArray(corrects[0])) {
      return corrects[0][index]
    } else {
      return corrects[index]
    }
  }

  const renderAssignment = (item) => {
    const regexp = /\[\(.*?\)\]/g

    const labels = [...item.assignment.matchAll(regexp)].map(elem => elem.toString().replace(/[\[\]]/g, '').replace(/[\(\)]/g, ''))
    if (labels.length === 0) {
      return <Markdown children={item.assignment} />
    }

    const textPieces = item.assignment.replace(regexp, '[_]').split('[_]')
    const toRender = [];

    for(let i = 0; i < textPieces.length -1; i++) {
      toRender.push(`${textPieces[i]}`)
      toRender.push(`<u data-index=${i} data-label="${labels[i]}" data-iscorrect="${item.correctness[i].is_correct ? 'correct' : 'wrong'}" data-answer="${item.correctness[i].user_answer}" data-correct="${item.correctness[i].correct_answer}">${item.correctness[i].user_answer}</u>`)
    }

    return <Markdown
      children={toRender.join(' ')}
      components={{
        u: ({node, ...props}) => <span
            className={`score-details__answer inline ${props["data-iscorrect"]}`}
            >{props["data-iscorrect"] === "correct"
              ? props["data-answer"]
              : <>
                <s>{props["data-answer"]}</s> {props["data-correct"]}</>
            }</span>
      }}
    />
  }

  const renderAnswersTable = (elem, index) => {
    if (Array.isArray(elem.type) && elem.type[0] === 'radio') {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {elem.correctness.map(correctness =>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className={`score-details__answer ${correctness.is_correct ? 'correct' : ''}`}>
                <div className={`score-details__answer__item ${correctness.is_correct ? 'correct' : ''}`}>
                  <Markdown children={correctness.user_answer} />
                </div>
              </div>
              <div className={`score-details__answer`}>
                <div className={`score-details__answer__item correct`}>
                  <Markdown children={correctness.user_answer} />
                </div>
              </div>
            </div>
          )}
        </div>
      )
    } else if (['oneoftwo', 'oneofthree'].includes(elem.type)) {
      return (
        <>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={`score-details__answer ${elem.correctness.is_correct ? 'correct' : ''}`}>
            <div className={`score-details__answer__item ${elem.correctness.is_correct ? 'correct' : ''}`}>
              <Markdown children={elem.correctness.user_answer} />
            </div>
          </div>
          <div className={`score-details__answer`}>
            <div className={`score-details__answer__item correct`}>
              <Markdown children={elem.correctness.user_answer} />
            </div>
          </div>
        </div>
        </>
      )
    } else {
      return ('')
    }
  }

  try {
    return (
      <div className="score">
        <div className="score-details">
          {props.score_details.map((elem, elemIndex) =>
            <div key={`row_${elemIndex}`} className="score-details__row">
              <div className="score-details__question">
                {Array.isArray(elem.assignment) ?
                  <ol start={elem.number + 1}>
                  <li>
                    {elem.assignment.map((assignmentItem, index) =>
                      <p key={`assignment_${elem.number}_${index}`}>
                        {assignmentItem}
                      </p>
                    )}
                    </li>
                  </ol>
                :
                <>
                {renderAssignment(elem)}
                </>
                }
              </div>
              {renderAnswersTable(elem, elemIndex)}
            </div>
          )}
        </div>
      </div>
    )
  }
  catch (e) {
    return (
      <div>
        <p>Results cannot be rendered. Please report it to the admins via Slack</p>
        <pre>{e}</pre>
      </div>
    )
  }
}

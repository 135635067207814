import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import { RefreshRounded } from '@mui/icons-material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import Stack from '@mui/material/Stack'

import Student from 'components/users/Student'
import AssignmentLink from 'components/elements/AssignmentLink'
import AssignmentResult from 'components/users/AssignmentResult'
import ScoreDetails from 'components/elements/ScoreDetails'
import LessonLink from 'components/elements/LessonLink'

import TabPanel, { a11yProps } from 'components/common/TabPanel'

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'
import Editor from 'components/elements/Editor'

export default function AssignmentResultDetailsPage() {
  const theme = useContext(ThemeContext)

  let { resultId } = useParams()

  const [result, setResult] = useState()
  const [value, setValue] = useState(0)
  const [errorMessage, setErrorMessage] = useState(false)
  const [isError, setIsError] = useState(false)
  const [recalcEnabled, setRecalcEnabled] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page

    rpc.post(
      "",
      buildRequest(
        "admin.assignments.result_details",
        { result_id: resultId },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setResult(response.data.result)
        setRecalcEnabled(true)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })
      .catch(e => {
        setErrorMessage("Server error")
        setIsError(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const recalc = (e) => {
    e.preventDefault()
    setRecalcEnabled(false)
    rpc.post(
      "",
      buildRequest(
        "admin.assignments.result_save",
        {
          result_id: resultId,
        }
      )
    ).then(response => {
      if (!response.data.result.message) {
        setResult(response.data.result)
        setRecalcEnabled(true)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })
      .catch(e => {
        setErrorMessage("Server error")
        setIsError(true)
      })
  }

  return (
    <>
      <Helmet>
        <title>Assignment Result</title>
      </Helmet>

      <Container fixed>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                <Typography variant="h4" gutterBottom>
                  Assignment Result
                </Typography>
                <Typography variant="h4" gutterBottom>

                  {result ?
                    <Student {...result.user} />
                    :
                    <CircularProgress />
                  }
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={3} divider={<Divider orientation="vertical" flexItem />}>
                {result ? <AssignmentLink elevation={1} {...result.assignment} /> : <CircularProgress />}
                {result ? <AssignmentResult elevation={1} {...result} /> : <CircularProgress />}
                {(result && result.lesson) && <LessonLink elevation={1} {...result.lesson} />}
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ padding: 1 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Pretty" {...a11yProps(0)} />
                  <Tab label="Raw answers / corrects" {...a11yProps(1)} />
                  <Tab label="Assignment YAML" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Alert severity="warning">If <strong>Pretty</strong> tab shows a lot of <code>undefined</code> see <strong>Raw answers / corrects</strong> tab</Alert>
                  {result ? <ScoreDetails {...result} /> : <CircularProgress />}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Alert severity="info">You see student's answers on the left column and <strong>real correct</strong> on the right. In case if you see that their structures are different you may be sure that the assignment itself has been changed and no more the <code>UserAssignmentResult</code> is corresponding the new version. Please report this Assignment and this Results to admins via Slack</Alert>
                  <Stack direction="row" spacing={3} divider={<Divider orientation="vertical" flexItem />}>
                    <pre>
                      {result ? JSON.stringify(result.answers, null, 2) : <CircularProgress />}
                    </pre>
                    <pre>
                      {result ? JSON.stringify(result.correct, null, 2) : <CircularProgress />}
                    </pre>
                  </Stack>
                  <Button
                    disabled={!recalcEnabled}
                    variant="contained"
                    startIcon={<RefreshRounded />}
                    onClick={recalc}
                  >
                    Recalculate
                  </Button>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {result ?
                    <Editor
                      defaultValue={result.assignment.source_yaml}
                      readOnly={true}
                      language="yaml"
                    />
                    :
                    <CircularProgress />
                  }
                </TabPanel>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isError}
        onClick={(e) => { setIsError(false) }}
      >
        <div>
          <p>{errorMessage}</p>
          <p>
            Let admins know about it
          </p>
        </div>
      </Backdrop>
    </>
  )
}

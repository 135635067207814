import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import Scheduler from 'components/elements/Scheduler'

import { ThemeContext } from 'components/common/App'

export default function HomePage() {
  const theme = useContext(ThemeContext)

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Container fixed>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" gutterBottom>
                Dashboard
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Scheduler />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  )
}

export const TEMPLATES = [
  {
    fullType: 'blank',
    type: 'blank',
    content: ``,
  },
  {
    fullType: 'v1_oneoftwo',
    type: 'oneoftwo',
    content: `version: 1
type: oneoftwo
name: CHANGE_ME
level: CHANGE_ME
questions:
  - meta:
      text: Complete the sentence with **This** or **That**
      translation: Закінчи речення з **Це** чи **То**
      assignment: |
        (**This** or **That**) is a book
    variants:
      - This
      - That
    correct: 1
  - meta:
      text: Complete the sentence with **This** or **That**
      translation: Закінчи речення з **Це** чи **То**
      assignment: |
        (**This** or **That**) is a bag
    variants:
      - This
      - That
    correct: 1
`
  },
  {
    fullType: 'v1_oneofthree',
    type: 'oneofthree',
    content: `version: 1
type: oneofthree
name: CHANGE_ME
level: CHANGE_ME
questions:
  - meta:
      text: Learn the words
      translation: Вивчи слова
      assignment: |
        **mole** - кріт
    variants:
      - Next
    correct: 0
  - meta:
      text: Learn the words
      translation: Вивчи слова
      assignment: |
        **rope** - канат
    variants:
      - Next
    correct: 0
`
  },
  {
    fullType: 'v1_input',
    type: 'input',
    content: `version: 1
type: input
name: CHANGE_ME
level: CHANGE_ME
questions:
  - meta:
      text: Type the missing word in
      translation: Впиши слово, якого невистачає
      assignment: |
        fall - fell - ... - падати
    correct: fallen
  - meta:
      text: Type the missing word in
      translation: Впиши слово, якого невистачає
      assignment: |
        feel -  ... - felt - відчувати
    correct: felt
`
  },
  {
    fullType: 'v2_input',
    type: 'input',
    content: `version: 2
type: input
name: CHANGE_ME
level: CHANGE_ME # A0, A1, A1+, A2, B1, B2, C1
questions:
  - meta:
      text: |
        Write down the translation
      translation: Впиши переклад англійською мовою
      assignment: |

        1. субота - [()]
        1. бейсбол - [()]

    correct:

      - saturday
      - baseball
`
  },
  {
    fullType: 'v2_theory',
    type: 'theory',
    content: `version: 2
type: theory
name: CHANGE_ME
level: CHANGE_ME
content: |

  REPLACE_ME
`
  },
  {
    fullType: 'v2_matchselect',
    type: 'matchselect',
    content: `version: 2
type: matchselect
name: CHANGE_ME
level: CHANGE_ME
questions:
  - meta:
      text: |
        Insert the missing letter
      translation: Встав пропущену літеру

      assignment: |
        1. c[(-)]p - кепка
        1. [(-)]loves - перчатки

      variants:
        - a
        - s

    correct:
      - a
      - g
`
  },
  {
    fullType: 'v2_radio',
    type: 'radio',
    content: `version: 2
type: radio
name: CHANGE_ME
level: CHANGE_ME
meta:
  text: Find the translation
  translation: Знайди переклад
questions:
  - meta:
      assignment: |
        четвер
    variants:
      - Monday
      - Thursday
      - tennis
    correct: 1
  - meta:
      assignment: |
        теніс
    variants:
      - Monday
      - Thursday
      - tennis
    correct: 2
`
  },
  {
    fullType: 'v2_radioinline',
    type: 'radioinline',
    content: `version: 2
type: radioinline
name: CHANGE_ME
level: CHANGE_ME
questions:
  - meta:
      text: |
        Choose **have** or **has**
      translation:

      assignment: |
        1. I [(have, has)] got a head - **в мене є голова**
        1. She [(have, has)] got 2 shoulders - **в неї є 2 плеча**

    correct:
      - have
      - has
`
  },
  {
    fullType: 'v2_wordcards',
    type: 'wordcards',
    content: `version: 2
type: wordcards
name: CHANGE_ME
level: CHANGE_ME
questions:
  - [[[huge]]]
  - [[[scientist]]]
`
  },
  {
    fullType: 'v3_exam',
    type: 'exam',
    content: `version: 3
type: exam
name: CHANGE_ME
level: CHANGE_ME
questions:
  - meta:
      type: radioinline
      name: CHANGE_ME
      questions:
        - meta:
            text: Read the text and choose the right option for each gap.
            translation:
            assignment: |
                ***Reading***

                ## Why Your ‘Weak-Tie’ Friendships May Mean More Than You Think


                For nearly 10 years, I have spent my Monday evenings attending rehearsals for my amateur choir. Mondays are not my favourite days, (1) [(A, B, C, D, E, F, G, H)], but by the end of the rehearsal, I usually feel energised. The singing does me good. So do the people.

                There is no choir practice now, and won’t be for a long time. I miss it. In lockdown, I don’t feel short of affection or emotional support, but I do feel short of friendly faces and casual conversations. Another way of putting this is (2) [(A, B, C, D, E, F, G, H)].
                ___________________________

                **A** but I don’t remember their names

                **B** that I miss my weak ties

                **C** that for new information and ideas

                **D** whom you spend a lot of time with

                **E** whom they saw only occasionally

                **F** but it has turned out that quantity matters

                **G** and I often arrive in a bad mood

                **H** whom you have never met before

          correct:
            - g
            - b

  - meta:
      type: radio
      meta:
        text: Vocabulary - find the translation
        translation: ""
        assignment: |
            ### Task 1 *Vocabulary*

            ## Feelings

            ![](https://cdn.icepig.online/media/assignments/busy_street_test.jpeg)
      questions:
        - meta:
            assignment: |
              upset
          variants:
            - засмучений
            - збентежений
            - самотній
          correct: 0
        - meta:
            assignment: |
              embarrassed
          variants:
            - самотній
            - знервований
            - зніяковілий
          correct: 2

  - meta:
      type: input
      name: CHANGE_ME
      questions:
        - meta:
            text: |
              Complete using the correct present simple form of the verbs in bracket
            translation: |
              Доповнити речення правильною формою дієслова в дужках
            assignment: |
              ## Present Simple

              ### Task 3

              1. My sister [(read)] a book.
              1. Frank [(like)] dogs.

          correct:
            - reads
            - likes
`
  }
]
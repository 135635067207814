// Generates an assignment YAML of type v2_input
// with words and their translations
// converts a text like:
// word - переклад
// word - переклад
//
// to:
// version: 2
// type: input
// name: check to en input
// level: A2
// questions:
//   - meta:
//       text: |
//         Write down the translation
//       translation: Впиши переклад англійською мовою
//       assignment: |
//         1. інший переклад - [()]
//         1. ще один переклад, або не один через кому - [()]
//         1. переклад - [()]
//     correct:
//       - another
//       - yet another one
//       - word
//
export function wordInputGenerator(rawText, topic, level, limit) {
  const lines = rawText.split('\n')
  let words = lines.map((line) => {
    const [word, translation] = line.split(' - ')
    if (!word || !translation) {
      return null
    }
    return { word: translation, translation: word }
  }).filter((w) => w)
  // shuffle words
  for (let i = words.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [words[i], words[j]] = [words[j], words[i]]
  }
  const assignments = words.slice(0, limit || words.length).map((word) => {
    return `1. ${word.word} - [()]`
  })
  const corrects = words.slice(0, limit || words.length).map((word) => word.translation.toLowerCase())

  const content = `version: 2
type: input
name: ${topic} input
level: ${level}
questions:
  - meta:
      text: |
        Write down the translation
      translation: Впиши переклад англійською мовою
      assignment: |

${assignments.map((a) => `        ${a}`).join('\n')}

    correct:

${corrects.map((c) => `      - ${c}`).join('\n')}
`
  return content
}
import React, { useState, useEffect } from 'react'
import Cropper from "react-cropper"

import { v4 as uuid } from 'uuid'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

import TransformIcon from '@mui/icons-material/Transform'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  minWidth: 300,
  maxHeight: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

export default function WordRow({ word, translation }) {
  const [errorBlock, setErrorBlock] = useState(null)

  const [id, setId] = useState(null)
  const [touched, setTouched] = useState(false)
  const [wordText, setWordText] = useState(word)
  const [translationText, setTranslationText] = useState(translation)
  const [sound, setSound] = useState(null)
  const [initialImage, setInitialImage] = useState(null)

  const [uploadedImageUrl, setUploadedImageUrl] = useState(null)
  const [filename, setFilename] = useState(null)
  const [newImageBase64, setNewImageBase64] = useState(null)
  const [cropper, setCropper] = useState(null)

  useEffect(() => {
    setTouched(false)

    rpc.post(
      "",
      buildRequest(
        "admin.vocabulary.words.create_single",
        {
          word: wordText,
          translation: translationText,
        }
      )
    ).then((response) => {
      if (!response.data.result.message) {
        setId(response.data.result.id)
        setInitialImage(response.data.result.image)
        setSound(response.data.result.audio)
        setTouched(false)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        setErrorBlock(response.data.result.message)
      }
    }).catch((error) => {
      console.error(error)
    })
  }, [])

  useEffect(() => {

  }, [wordText, translationText, newImageBase64])

  const getImageUrl = (e) => {
    if (e.target.files) {
      setNewImageBase64(null)
      // Encode the file using the FileReader API
      const reader = new FileReader()
      reader.onloadend = () => {
        setNewImageBase64(reader.result)
        setTouched(true)
      }
      reader.readAsDataURL(e.target.files[0])
      setFilename(`${uuid()}.${e.target.files[0].name.split('.').pop()}`)
      setUploadedImageUrl(URL.createObjectURL(e.target.files[0]))
    }
  }

  const getCropData = async () => {
    if (cropper) {
      setNewImageBase64(cropper.getCroppedCanvas().toDataURL())
      setTouched(true)
      setCropper(null)
      setUploadedImageUrl(null)
    }
  }

  const handleCloseCropModal = (event, reason) => {
    setTouched(true)
    setCropper(null)
    setUploadedImageUrl(null)
  }

  const saveWord = (e) => {
    if ([
      wordText.length > 0,
      translationText.length > 0,
      touched,
    ].some(x => !x)) {
      return false
    }

    let image = null

    if (newImageBase64) {
      image = {
        name: filename,
        base64: newImageBase64,
      }
    }

    rpc.post(
      "",
      buildRequest(
        "admin.vocabulary.words.update",
        {
          word_id: id,
          word: wordText,
          translation: translationText,
          image,
          sound,
        }
      )
    )
      .then((response) => {
        if (!response.data.result.message) {
          setWordText(response.data.result.word)
          setTranslationText(response.data.result.translation)
          setSound(response.data.result.audio)
          setNewImageBase64(null)
          setInitialImage(response.data.result.image)
          setTouched(false)
        } else {
          if (response.data.result.code === 5) {
            signoutAndRedirectToIndex()
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
  if (errorBlock) {
    return (
      <Paper sx={{ padding: 1, marginBottom: 2 }}>
        <Alert severity="error">{errorBlock}</Alert>
      </Paper>
    )
  }
  return (
    <Paper sx={{ padding: 1, marginBottom: 2 }}>
      <Stack direction="row" spacing={1} justifyContent={'space-between'}>

        <Stack spacing={1}>
          <TextField
            label="Word"
            error={wordText.length < 1}
            helperText={wordText.length > 0 ? "" : "Word is required"}
            value={wordText}
            onChange={(e) => {
              setWordText(e.target.value)
              setTouched(true)
            }}
          />

          <TextField
            label="Translation"
            error={translationText.length < 1}
            helperText={translationText.length > 0 ? "" : "Translation is required"}
            value={translationText}
            onChange={(e) => {
              setTranslationText(e.target.value)
              setTouched(true)
            }}
          />
        </Stack>

        <Stack spacing={1}>
          <Typography variant="overline" gutterBottom>
            Sound
          </Typography>
          {!id && <CircularProgress />}
          {id && (
            <audio
              controls
              src={sound}
            />
          )}
        </Stack>

        <Stack spacing={1}>
          {!id && <CircularProgress />}
          {id && (
            <>
              <Button variant="outlined" component="label">
                Upload image
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
                  hidden
                  onChange={getImageUrl}
                />
              </Button>
              {(initialImage && !newImageBase64) && (
                <img
                  src={initialImage}
                  alt={wordText}
                  style={{ maxHeight: 148 }}
                />
              )}
              {newImageBase64 && (
                <img
                  src={newImageBase64}
                  alt={wordText}
                  style={{ maxHeight: 148 }}
                />
              )}
            </>
          )}
        </Stack>
      </Stack>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          disabled={
            !id ||
            [
              wordText.length > 0,
              translationText.length > 0,
              touched,
            ].some(x => !x)
          }
          variant={touched ? "contained" : "outlined"}
          color="primary"
          onClick={saveWord}
        >
          Save
        </Button>
      </Box>

      <Modal
        open={!!uploadedImageUrl && !newImageBase64}
        onClose={handleCloseCropModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={MODAL_STYLE}>
          <Stack padding={1} spacing={1}>
            <Cropper
              src={uploadedImageUrl}
              style={{ height: 400, width: 400 }}
              aspectRatio={268 / 148}
              minCropBoxWidth={228}
              minCropBoxHeight={148}
              guides={false}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
            <Button variant="contained" onClick={getCropData}>
              <TransformIcon /> Crop Image
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Paper>
  )
}
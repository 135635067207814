/// This is a set of utils for working with assignments
/// mostly focused on the checking the assignment

/// Based on the assignment full type (e.g. "v2_radio") and the dataJson
/// this function should return a request object that can be used to submit
/// the assignment to the backend to achieve the 100% correct result
export function buildAssignmentSubmitRequest(assignmentFullType, dataJson) {
  switch (assignmentFullType) {
    case "v2_radio":
    case "v1_oneoftwo":
    case "v1_oneofthree":
    case "radio": // exam type
      /// map over the dataJson.questions and return an object where key is the question index and the value is the question.correct value
      /// this will be used to submit the assignment to the backend
      return dataJson.questions.reduce((acc, question, index) => {
        acc[index.toString()] = question.correct;
        return acc;
      }, {});
    case "v2_input":
    case "v2_matchselect":
    case "v2_radioinline":
      return dataJson.questions.reduce((acc, question, index) => {
        acc[index.toString()] = question.correct.map(correct => correct.split('|')[0]);
        return acc;
      }, {});
    case "v1_input":
      return dataJson.questions.reduce((acc, question, index) => {
        acc[index.toString()] = question.correct;
        return acc;
      }, {});
    case "v3_exam":
      /// This is a special case since the exam acts more like a container for other assignments
      /// So we need to iterate over the questions and call the buildAssignmentSubmitRequest for
      // each question based on the type
      return dataJson.questions.reduce((acc, question, index) => {
        acc[index.toString()] = buildAssignmentSubmitRequest(question.meta.type, question.meta);
        return acc;
      }, {});
    case "radioinline": // exam type
      return dataJson.questions.flatMap(question => question.correct);
    case "input": // exam type
      return dataJson.questions.flatMap(question => question.correct).map(correct => correct.split('|')[0]);
    default:
      return {}
  }
}

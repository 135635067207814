import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  maxWidth: 150,
}));


export default function AssignmentResult(props) {
  return (
    <Item elevation={props.elevation || 0}>
      <Stack spacing={0} alignItems="center">
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-start"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Typography variant="overline" sx={{ fontSize: "130%" }} display="block">
            <Link to={`/results/${props.id}`} component={RouterLink}>{props.score}%</Link>
          </Typography>
          <Typography sx={{ fontSize: "230%" }} display="block">
            {props.emoji}
          </Typography>
        </Stack>
        <Typography variant="secondary">
          {props.updated_on}
        </Typography>
      </Stack>
    </Item>
  )
}

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

import AssignmentTypeIcon from 'components/elements/AssignmentTypeIcon'

export default function AssignmentTypeFilter(props) {
  const hanleChange = (event, newValue) => {
    props.onChange(newValue)
  }

  return (
    <ToggleButtonGroup
      exclusive
      sx={{ flexWrap: 'wrap' }}
      value={props.value}
      onChange={hanleChange}
      aria-label="filter by assignment type"
    >
      <ToggleButton value={null} aria-label="None">
        <FilterAltOffIcon />
      </ToggleButton>
      <ToggleButton value="oneoftwo" aria-label="oneoftwo">
        <AssignmentTypeIcon type="oneoftwo" fullType="v1_oneoftwo" />
      </ToggleButton>
      <ToggleButton value="oneofthree" aria-label="oneofthree">
        <AssignmentTypeIcon type="oneofthree" fullType="v1_oneofthree" />
      </ToggleButton>
      <ToggleButton value="input" aria-label="input">
        <AssignmentTypeIcon type="input" fullType="v1_input" />
      </ToggleButton>
      <ToggleButton value="theory" aria-label="theory">
        <AssignmentTypeIcon type="theory" fullType="v2_theory" />
      </ToggleButton>
      <ToggleButton value="matchselect" aria-label="matchselect">
        <AssignmentTypeIcon type="matchselect" fullType="v2_matchselect" />
      </ToggleButton>
      <ToggleButton value="radio" aria-label="radio">
        <AssignmentTypeIcon type="radio" fullType="v2_radio" />
      </ToggleButton>
      <ToggleButton value="radioinline" aria-label="radioinline">
        <AssignmentTypeIcon type="radioinline" fullType="v2_radioinline" />
      </ToggleButton>
      <ToggleButton value="exam" aria-label="exam">
        <AssignmentTypeIcon type="exam" fullType="v3_exam" />
      </ToggleButton>
      <ToggleButton value="wordcards" aria-label="wordcards">
        <AssignmentTypeIcon type="wordcards" fullType="v2_wordcards" />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
import TextFieldsIcon from '@mui/icons-material/TextFields' // input
import ViewStreamIcon from '@mui/icons-material/ViewStream' // one of two
import TableRowsIcon from '@mui/icons-material/TableRows' // one of three
import GradingIcon from '@mui/icons-material/Grading' // theory
import EditAttributesIcon from '@mui/icons-material/EditAttributes' // radio
import PendingActionsIcon from '@mui/icons-material/PendingActions' // exam
import HdrWeakIcon from '@mui/icons-material/HdrWeak' // radioinline
import HelpOutlineIcon from '@mui/icons-material/HelpOutline' // unknown
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown' // matchselect
import FilterIcon from '@mui/icons-material/Filter' // wordcards

import Tooltip from '@mui/material/Tooltip'

export default function assignmentType({ type, fullType }) {
  const icon = (type) => {
    switch (type) {
      case 'oneoftwo':
        return <ViewStreamIcon />
      case 'input':
        return <TextFieldsIcon />
      case 'theory':
        return <GradingIcon />
      case 'exam':
        return <PendingActionsIcon />
      case 'radio':
        return <EditAttributesIcon />
      case 'radioinline':
        return <HdrWeakIcon />
      case 'matchselect':
        return <ArrowDropDownIcon />
      case 'oneofthree':
        return <TableRowsIcon />
      case 'wordcards':
        return <FilterIcon />
      default:
        return <HelpOutlineIcon />
    }
  }

  return (
    <Tooltip title={fullType}>
      {icon(type)}
    </Tooltip>
  )
}
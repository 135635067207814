import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'

export default function Pagination(props) {
  const handleChangePage = (_, newPage) => {
    props.setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (event) => {
    props.setPerPage(parseInt(event.target.value, 10));
    props.setPage(1);
  }

  const Container = props.container || TableFooter

  return (
    <Container>
      <TableRow>
        <TablePagination
          showFirstButton={true}
          showLastButton={true}
          rowsPerPageOptions={[10, 25, 100]}
          count={props.total}
          rowsPerPage={props.perPage}
          page={props.page - 1}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage} />
      </TableRow>
    </Container>
  )
}
import React, { useState, useContext, useEffect } from 'react'
import { enqueueSnackbar } from 'notistack'
import { v4 as uuid } from 'uuid'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import Cropper from "react-cropper"
import "cropperjs/dist/cropper.css"

import CircularProgress from '@mui/material/CircularProgress'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import SaveIcon from '@mui/icons-material/Save'
import TransformIcon from '@mui/icons-material/Transform'
import CasinoIcon from '@mui/icons-material/Casino';

import { ThemeContext } from 'components/common/App'

import { CDN_URL } from 'config'
import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'
import transliterate from 'lib/transliterate'

export default function UserCreatePage() {
  const theme = useContext(ThemeContext)
  const navigate = useNavigate()

  const [user, setUser] = useState({ role: "student", is_active: true })
  const [newPassword, setNewPassword] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fabColor, setFabColor] = useState("primary")
  const [highlightErrors, setHighlightErrors] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const [imageUrl, setImageUrl] = useState(null)
  const [croppedImageBase64, setCroppedImageBase64] = useState(null)
  const [imageBase64, setImageBase64] = useState(null)
  const [filename, setFilename] = useState(null)
  const [cropper, setCropper] = useState(null)

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const generatePassword = () => {
    if (user.first_name && user.first_name.length > 0 && user.last_name && user.last_name.length > 0) {
      let firstPart = transliterate(user.first_name).toLowerCase()
      let secondPart = transliterate(user.last_name).toLowerCase()
      let password = firstPart.slice(0, 3) + secondPart.slice(0, 4)
      // randomly make some letters uppercase
      let passwordArray = password.split("")
      for (let i = 0; i < passwordArray.length; i++) {
        if (Math.random() > 0.5) {
          passwordArray[i] = passwordArray[i].toUpperCase()
        }
      }
      password = passwordArray.join("")
      return password
    } else {
      return (uuid().split("-")[0])
    }
  }

  const saveUser = async () => {
    if ([
      user.phone && user.phone.length > 0,
      user.phone && user.phone.length <= 10,
      user.first_name && user.first_name.length > 0,
      user.last_name && user.last_name.length > 0,
      newPassword && newPassword.length > 0,
    ].some(v => !v)) {
      setHighlightErrors(true)
      setFabColor("error")
      setTimeout(() => {
        setFabColor("primary")
      }, 1000)
      return false;
    }
    setLoading(true)
    setErrorMessage(null)

    if (!user) return;

    let password = null;
    if (newPassword && newPassword.length > 0) {
      password = newPassword;
    }

    let avatar = null;
    // if we're changing the photo, we need to generate a URL for it and upload it asynchronously
    if (croppedImageBase64 || imageBase64) {
      avatar = {
        filename,
        content: croppedImageBase64 || imageBase64
      }
      user.photo = `${CDN_URL}/media/avatars/${filename}`
    }

    rpc.post(
      "",
      buildRequest("admin.users.create", {
        password,
        avatar,
        phone: user.phone,
        first_name: user.first_name,
        last_name: user.last_name,
        is_active: user.is_active,
        role: user.role,
      })
    )
      .then(({ data }) => {
        if (!data.result.code) {
          enqueueSnackbar('User created', { variant: 'success' })
          navigate(`/users/details/${data.result.id}`)
        } else {
          if (data.error.code === 5) {
            signoutAndRedirectToIndex()
          }
          console.error(data.error)
          enqueueSnackbar(data.error.message, { variant: 'error' })
          setErrorMessage(data.error.message)
          setFabColor("error")
          setTimeout(() => {
            setFabColor("primary")
          }, 1000)
        }
        setLoading(false)
      })
  }

  const getImageUrl = (e) => {
    if (e.target.files) {
      // Encode the file using the FileReader API
      const reader = new FileReader()
      reader.onloadend = () => {
        setImageBase64(reader.result)
      }
      reader.readAsDataURL(e.target.files[0])
      setFilename(`${uuid()}.${e.target.files[0].name.split('.').pop()}`)
      setImageUrl(URL.createObjectURL(e.target.files[0]))
    }
  }

  const getCropData = async () => {
    if (cropper) {
      setCroppedImageBase64(cropper.getCroppedCanvas().toDataURL())
    }
  }

  return (
    <>
      <Helmet>
        <title>User {user ? `${user.phone} ${user.firstName} ${user.lastName}` : '...'}</title>
      </Helmet>

      <Fab
        color={fabColor}
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={saveUser}
      >
        {loading ? <CircularProgress color="inherit" /> : <SaveIcon />}
      </Fab>

      <Container fixed>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent={'space-between'}
                flexWrap={'wrap'}
              >
                <Typography variant="h3" gutterBottom>
                  User {user.phone ? user.phone : '...'} {user.first_name ? user.first_name : '...'} {user.last_name ? user.last_name : '...'}
                </Typography>
              </Stack>
            </Grid>
            {errorMessage && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Paper>
              </Grid>
            )}
            <Grid item xs={12} md={8}>
              <Paper>
                <Stack spacing={2} sx={{ p: 2 }}>
                  <TextField
                    required
                    error={highlightErrors && (!user || !user.phone || user.phone.length === 0)}
                    id="phone"
                    label="Телефон"
                    value={(user && user.phone) || ''}
                    onChange={(e) => setUser({ ...user, phone: e.target.value })}
                  // disabled={!user}
                  />

                  <TextField
                    required
                    error={highlightErrors && (!user || !user.first_name || user.first_name.length === 0)}
                    id="first_name"
                    label="Ім'я"
                    value={(user && user.first_name) || ''}
                    onChange={(e) => setUser({ ...user, first_name: e.target.value })}
                    disabled={!user}
                  />

                  <TextField
                    required
                    error={highlightErrors && (!user || !user.last_name || user.last_name.length === 0)}
                    id="last_name"
                    label="Прізвище"
                    value={(user && user.last_name) || ''}
                    onChange={(e) => setUser({ ...user, last_name: e.target.value })}
                    disabled={!user}
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        checked={user.is_active}
                        onChange={(e) => setUser({ ...user, is_active: e.target.checked })}
                      />
                    }
                    label={(user && user.is_active) ? "Активний" : "Неактивний"}
                  />

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={(user && user.role) || 'student'}
                    label="Роль"
                    helperText="Оберіть роль"
                    onChange={(e) => setUser({ ...user, role: e.target.value })}
                  >
                    <MenuItem value={"student"}>👶 Student</MenuItem>
                    <MenuItem value={"teacher"}>👩‍🏫 Teacher</MenuItem>
                    <MenuItem value={"admin"}>👩‍💼 Admin</MenuItem>
                    <MenuItem value={"superadmin"}>🦸‍♂️ Superadmin</MenuItem>
                  </Select>
                  <Divider />
                  <Stack
                    direction={'row'}
                    spacing={1}
                    justifyContent={'space-between'}
                    sx={{ width: '100%' }}
                  >
                    <TextField
                      required
                      error={highlightErrors && (!user || newPassword || newPassword.length === 0)}
                      id="password"
                      fullWidth
                      label="Задати пароль пароль"
                      value={newPassword || ''}
                      onChange={(e) => setNewPassword(e.target.value)}
                      disabled={!user}
                    />
                    <Button variant="contained" onClick={() => setNewPassword(generatePassword())}>
                      <CasinoIcon />
                    </Button>
                  </Stack>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper>
                <Stack spacing={2} sx={{ p: 2 }}>
                  {imageUrl ? (
                    <>
                      <img src={croppedImageBase64 || imageUrl} alt="User" />
                    </>
                  ) : (
                    <img
                      src={(user && user.photo) || "https://cdn.icepig.online/static/images/avatars/default.png"}
                      alt="User"
                      style={{
                        width: 75,
                        height: 75,
                        borderRadius: '50%',
                        backgroundColor: '#ccc',
                        margin: '0 auto',
                      }}
                    />
                  )}

                  <Button
                    variant="contained"
                    component="label"
                  >
                    Upload image
                    <input
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
                      hidden
                      onChange={getImageUrl}
                    />
                  </Button>
                </Stack>
              </Paper>
            </Grid>
            {(imageUrl && !croppedImageBase64) && (
              <Grid item xs={12}>
                <Paper>
                  <Stack padding={1} spacing={1}>
                    <Cropper
                      src={imageUrl}
                      style={{ height: 400, width: 400 }}
                      aspectRatio={1 / 1}
                      minCropBoxWidth={200}
                      minCropBoxHeight={200}
                      guides={false}
                      checkOrientation={false}
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                    />
                    <Button variant="contained" onClick={getCropData}>
                      <TransformIcon /> Crop Image
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container >
    </>
  )
}

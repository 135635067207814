import { useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom'

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MuiAppBar from '@mui/material/AppBar';
import React from 'react'

import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TranslateIcon from '@mui/icons-material/Translate';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import AbcIcon from '@mui/icons-material/Abc';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import CachedIcon from '@mui/icons-material/Cached';

import { VERSION } from 'config'

import { UserContext } from 'components/common/App'

import { signoutAndRedirectToIndex } from 'lib/rpc'

const pages = [
  { text: 'Summary', link: '/', Icon: DashboardIcon },
  { text: 'Groups', link: '/groups', Icon: GroupsIcon },
  { text: 'Assigments', link: '/assignments', Icon: AssignmentIcon },
  { text: 'Words', link: '/words', Icon: TranslateIcon },
  { text: 'Users', link: '/users', Icon: FolderSharedIcon },
  {
    text: 'Generator', link: '/generators', Icon: PrecisionManufacturingIcon, children: [
      { text: 'Voice', link: '/generators/voice', Icon: RecordVoiceOverIcon },
      { text: 'Words', link: '/generators/words', Icon: AbcIcon }
    ]
  },
  {
    text: 'Video Library', link: '/videos', Icon: OndemandVideoIcon, children: [
      { text: 'Upload', link: '/videos', Icon: VideoCallIcon },
      { text: 'Library', link: '/videos/library', Icon: FolderOpenIcon }
    ]
  },
];
const settings = [
  {
    text: 'Admin Panel',
    onClick: (e) => { window.location.replace('https://console.icepig.online/admin/') },
  },
  {
    text: <span><CachedIcon /> Version: {VERSION}</span>,
    onClick: (e) => { window.location.reload() },
  },
  {
    text: 'Logout',
    onClick: (e) => {
      signoutAndRedirectToIndex()
    }
  }
];

const drawerWidth = 240;

export default function Header() {

  const navigate = useNavigate()

  const theme = useTheme();
  const user = useContext(UserContext);
  const [userMenu, setUserMenu] = useState(null);
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img src="/favicon.png" alt="favicon" style={{ display: open ? 'none' : 'block' }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              ...(open && { display: 'none' }),
            }}
          >
            IcePig
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" noWrap component="div">
              Teacher Admin Panel
            </Typography>
            {user &&
              <Tooltip title="Open settings">
                <IconButton onClick={(e) => setUserMenu(e.target)} sx={{ p: 0 }}>
                  <Avatar alt={`${user.first_name} ${user.last_name}`} src={user.photo} />
                </IconButton>
              </Tooltip>
            }
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={userMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(userMenu)}
              onClose={() => setUserMenu(false)}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.text} onClick={() => setUserMenu(false)}>
                  <Typography
                    textAlign="center"
                    onClick={setting.onClick}
                  >{setting.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img src="/favicon.png" alt="favicon" style={{ display: !open ? 'none' : 'block' }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              ...(!open && { display: 'none' }),
            }}
          >
            IcePig
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {user &&
          <List>
            {pages.map((page, index) => (
              <ListItem
                disablePadding
                key={page.text}
                sx={{ display: 'block' }}
              >
                <ListItemButton
                  onClick={() => navigate(page.link)}
                  selected={page.link === '/' ? window.location.pathname === '/' : window.location.pathname.startsWith(page.link)}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <page.Icon />
                  </ListItemIcon>
                  <ListItemText primary={page.text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
                {page.children && <List>
                  {page.children.map(child =>
                    <ListItem
                      disablePadding
                      key={child.text}
                      sx={{ display: 'block', pl: open ? 4 : 0 }}
                    >
                      <ListItemButton
                        onClick={() => navigate(child.link)}
                        selected={child.link === '/' ? window.location.pathname === '/' : window.location.pathname.startsWith(child.link)}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                          }}
                        >
                          <child.Icon />
                        </ListItemIcon>
                        <ListItemText primary={child.text} sx={{ opacity: open ? 1 : 0 }} />
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>}
              </ListItem>
            ))}

          </List>
        }
        <Divider />
        <Typography
          variant="caption"
          textAlign={'center'}
          sx={{ px: 2.5, pb: 2.5, color: 'text.secondary' }}
        >
          v.{VERSION} &copy; 2023 IcePig
        </Typography>
      </Drawer>
    </>
  );
};

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
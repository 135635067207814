/*
Custom plugin to replace the link ending with .mp3 in markdown into audio tag

Usage:

```
http://192.168.106.18:8000/media/audio/hotel1701.mp3
```
*/
import { visit } from 'unist-util-visit';
import { v4 as uuid } from 'uuid';

export default function rehypeAudio(options) {
  const transformer = (ast) => {
    visit(ast, 'element', (node) => {
      if (node.tagName === "a" && node.properties.href.endsWith(".mp3")) {
        node.type = "element"
        node.tagName = "audio"
        node.properties = {
          id: uuid(),
          src: node.properties.href,
          controls: true,
          onContextMenu: "return false;",
          controlList: ["nodownload"],
        }
      }
    })
  };
  return transformer;
};

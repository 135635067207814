import React, { useContext, useEffect, useState } from 'react'
import { useConfirm } from "material-ui-confirm";
import { enqueueSnackbar } from 'notistack'
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import LessonsForm from 'components/forms/Lessons'
import TeacherSelector from 'components/forms/TeacherSelector';
import GroupStudents from 'components/forms/GroupStudents';

import Link from '@mui/material/Link';

import Stack from '@mui/material/Stack';

import Student from 'components/users/Student'
import AssignmentLink from 'components/elements/AssignmentLink'
import Pagination from 'components/elements/Pagination'
import TabPanel, { a11yProps } from 'components/common/TabPanel'

import { ThemeContext } from 'components/common/App'

import rpc, { buildRequest, signoutAndRedirectToIndex } from 'lib/rpc'

export default function GroupDetailPage() {
  const theme = useContext(ThemeContext)
  const confirm = useConfirm()

  const navigate = useNavigate()
  let { groupId, page = 1 } = useParams()

  const [tabValue, setTabValue] = useState(0)
  const [lessons, setLessons] = useState()
  const [veryLastLesson, setVeryLastLesson] = useState(null)
  const [newLessons, setNewLessons] = useState([])
  const [group, setGroup] = useState()

  const [fabColor, setFabColor] = useState("primary")
  const [loading, setLoading] = useState(false)
  const [dependencyRefresher, setDependencyRefresher] = useState(0)

  const [perPage, setPerPage] = React.useState(10);
  const [total, setTotal] = React.useState(null);

  useEffect(() => {
    theme.changeTheme("light-bg")
    window.scrollTo(0, 0) // Make sure to be on top of the page

    // Fetch base data about the group from RPC
    rpc.post(
      "",
      buildRequest(
        "admin.groups.get",
        { group_id: groupId },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setGroup(response.data.result)
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setLessons(null)

    // Fetch lessons for the group from RPC based on pagination parameters
    rpc.post(
      "",
      buildRequest(
        "admin.groups.lessons_list",
        {
          group_id: groupId,
          page: page,
          per_page: perPage,
        },
      )
    ).then(response => {
      if (!response.data.result.message) {
        setLessons(response.data.result.data)
        setTotal(response.data.result.records)
        if (!veryLastLesson && response.data.result.data.length > 0) {
          setVeryLastLesson(response.data.result.data[0])
        }
      } else {
        if (response.data.result.code === 5) {
          signoutAndRedirectToIndex()
        }
        console.error(response.data.result)
      }
    })
  }, [page, perPage])

  const updateGroup = () => {
    if (newLessons.length > 0) {
      confirm({
        description: `This update will add ${newLessons.length} lessons to this group. Are you sure?`,
      }).then(() => {
        _updateGroup()
        _addLessonsBulk()
      })
        .catch(e => {
          // user clicked cancel, do nothing
        })
    } else {
      _updateGroup()
    }
  }

  const _updateGroup = () => {
    setLoading(true)
    rpc.post("",
      buildRequest("admin.groups.update", {
        group_id: groupId,
        updates: {
          name: group.name,
          conference_link: group.conference_link,
          teacher_id: group.teacher.id,
        }
      })
    ).then(({ data, errors }) => {
      if (!data.result.code) {
        enqueueSnackbar(`Group ${group.name} updated`, { variant: 'success' })
        setFabColor("success")
        setLoading(false)
      } else {
        enqueueSnackbar(`Error updating group ${groupId}: ${data.result.message}`, { variant: 'error' })
        setFabColor("error")
        setLoading(false)
      }
    })
  }

  const _addLessonsBulk = () => {
    rpc.post("",
      buildRequest("admin.groups.add_lessons_bulk", {
        group_id: groupId,
        lessons: newLessons.map(lesson => ({ ...lesson, scheduled_on: lesson.scheduled_on.tz("Europe/Kyiv").format("YYYY-MM-DD+HH:mm:ss") })),
      })
    ).then(({ data, errors }) => {
      if (!data.result.code) {
        setPage(1)
        enqueueSnackbar(`${newLessons.length} lessons added`, { variant: 'success' })
        setFabColor("success")
        setLoading(false)
      } else {
        enqueueSnackbar(`Error adding lessons: ${data.result.message}`, { variant: 'error' })
        setFabColor("error")
        setLoading(false)
      }
    })
  }

  const handleStudentRemoved = (student) => {
    // admin.groups.remove_student
    confirm({
      description: `Are you sure you want to remove ${student.first_name} ${student.last_name} from this group?`,
    }).then(() => {
      rpc.post("", buildRequest("admin.groups.remove_student", {
        group_id: groupId,
        student_id: student.id,
      })).then(({ data, errors }) => {
        if (data.result) {
          enqueueSnackbar(`${student.first_name} ${student.last_name} removed from group`, { variant: 'success' })
          setDependencyRefresher(dependencyRefresher + 1)
          setGroup({ ...group, students: group.students.filter(s => s.id !== student.id) })
        }
      })
    })
      .catch(e => {
        // user clicked cancel, do nothing
      })
  }

  const handleStudentAdded = (student) => {
    setGroup({ ...group, students: [...group.students, student] })
  }

  const deleteGroup = () => {
    confirm({
      description: `Are you sure you want to delete group ${group.name}?`,
    }).then(() => {
      rpc.post("", buildRequest("admin.groups.delete", {
        group_id: groupId,
      })).then(({ data, errors }) => {
        if (data.result) {
          enqueueSnackbar(`Group ${group.name} deleted`, { variant: 'success' })
          navigate('/groups')
        }
      })
    })
      .catch(e => {
        // user clicked cancel, do nothing
      })
  }

  const setPage = (newPage) => {
    navigate(`/groups/details/${groupId}/${newPage}`, { replace: true })
  }

  const handleTabValueChange = (event, newValue) => {
    setTabValue(newValue);
  }

  return (
    <>
      <Helmet>
        {group ?
          <title>{group && group.name} Lessons</title>
          :
          <title>Loading...</title>
        }
      </Helmet>
      <Container fixed>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                <Typography variant="h4" gutterBottom>
                  {group && group.name}
                </Typography>

                <Tabs value={tabValue} onChange={handleTabValueChange} aria-label="basic tabs example">
                  <Tab label="Details" {...a11yProps(0)} />
                  <Tab label="Edit" {...a11yProps(1)} />
                  <Tab label="Students" {...a11yProps(2)} />
                </Tabs>
              </Stack>
            </Grid>
          </Grid>

          <TabPanel value={tabValue} index={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {group ?
                  <Paper sx={{ padding: 2 }}>
                    {group.students &&
                      <Stack direction="row" spacing={2}>
                        {group.students.map(student =>
                          <Student key={`student-${student.id}`} {...student} />
                        )}
                      </Stack>
                    }
                  </Paper>
                  :
                  <CircularProgress />
                }
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell align="left">Number</TableCell>
                          <TableCell align="left">Scheduled on</TableCell>
                          <TableCell align="left">Assignments</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lessons ? lessons.map(lesson =>
                          <TableRow key={`lesson-${lesson.id}`}>
                            <TableCell style={{ verticalAlign: 'top' }}>
                              <Link to={`/groups/details/${groupId}/lesson/${lesson.id}`} component={RouterLink}>
                                {lesson.id}
                              </Link>
                            </TableCell>
                            <TableCell style={{ verticalAlign: 'top' }}>
                              <Link to={`/groups/details/${groupId}/lesson/${lesson.id}`} component={RouterLink}>
                                {lesson.number}
                              </Link>
                            </TableCell>
                            <TableCell style={{ verticalAlign: 'top' }}>
                              {lesson.scheduled_on}
                            </TableCell>
                            <TableCell style={{ verticalAlign: 'top' }}>
                              {lesson.assignments &&
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={`assignments-accordion-${lesson.id}`}
                                  >
                                    <Typography>{lesson.assignments.length} assignments...</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Stack spacing={1}>
                                      {lesson.assignments.map(assignment =>
                                        <AssignmentLink
                                          key={`lesson-${lesson.id}__assignment-${assignment.id}`}
                                          {...assignment}
                                        />
                                      )}
                                    </Stack>
                                  </AccordionDetails>
                                </Accordion>
                              }
                            </TableCell>
                          </TableRow>
                        )
                          :
                          <TableRow>
                            <TableCell colSpan={4}>
                              <CircularProgress />
                            </TableCell>
                          </TableRow>
                        }
                      </TableBody>
                      {lessons &&
                        <Pagination
                          page={page}
                          perPage={perPage}
                          total={total}
                          setPage={setPage}
                          setPerPage={setPerPage}
                        />
                      }
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>

            <Fab
              color={fabColor}
              aria-label="save"
              sx={{ position: 'fixed', bottom: 16, right: 16 }}
              onClick={updateGroup}
            >
              {loading ? <CircularProgress color="inherit" /> : <SaveIcon />}
            </Fab>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Typography variant="h5" gutterBottom>
                      Edit group
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Start date: {group && moment(group.start_date).format('DD-MM-YYYY')}
                    </Typography>
                    <TextField
                      id="name"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      value={group && group.name}
                      onChange={(e) => setGroup({ ...group, name: e.target.value })}
                    />
                    <TextField
                      id="conference_link"
                      label="Conference link"
                      variant="outlined"
                      fullWidth
                      value={group && group.conference_link}
                      onChange={(e) => setGroup({ ...group, conference_link: e.target.value })}
                    />
                    <TeacherSelector teacher={group && group.teacher} setTeacher={(newTeacher) => setGroup({ ...group, teacher: newTeacher })} />

                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                {group &&
                  <LessonsForm
                    startNumber={veryLastLesson ? veryLastLesson.number : 0}
                    startDate={
                      veryLastLesson ?
                        moment(veryLastLesson.scheduled_on, "DD.MM.YYYY").add(1, 'day')
                        : moment(group.start_date)}
                    attachLessons={setNewLessons}
                  />
                }
              </Grid>
              <Grid item xs={12}>
                <Button variant="outlined" onClick={deleteGroup} color="error">Delete group</Button>
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                {group ?
                  <Paper sx={{ padding: 2 }}>
                    {group.students && group.students.length > 0 ?
                      <Stack spacing={1} flexGrow={1}>
                        {group.students.map(student =>
                          <Stack key={`student - ${student.id}`} direction={'row'} justifyContent={'space-between'}>
                            <Student  {...student} />
                            <Button variant="outlined" onClick={() => handleStudentRemoved(student)} startIcon={<PersonRemoveIcon />}>Remove</Button>
                          </Stack>
                        )}
                      </Stack>
                      : "No students in this group yet."
                    }
                  </Paper>
                  :
                  <CircularProgress />
                }
              </Grid>

              <Grid item xs={12}>
                <Paper sx={{ width: '100%', p: 2 }}>
                  {group &&
                    <GroupStudents
                      groupId={group.id}
                      onAddStudent={handleStudentAdded}
                      refresher={dependencyRefresher}
                    />
                  }
                </Paper>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </Container>
    </>
  )
}
